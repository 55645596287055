import produce from "immer";

const INITIAL_STATE = {
  modalUser: false,
  modalEmail: false,
  tenantProfile: "",
  itemsMenu: ["all"],
};
export default function header(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@header/TOOGLE_MODAL_USER":
        draft.modalUser = !draft.modalUser;
        break;
      case "@header/TOOGLE_MODAL_EMAIL":
        draft.modalEmail = !draft.modalEmail;
        break;
      case "@auth/SIGN_IN_SUCCESS":
        if (
          !action.payload.user.emailConfirmed &&
          JSON.stringify(action.payload.user.updateEmail) === "{}" &&
          action.payload.type === "student"
        ) {
          draft.modalEmail = true;
        }
        break;
      case "@header/UPDATE_TENANT_PROFILE":
        draft.tenantProfile = action.payload.tenant;
        break;
      case "@header/SET_ITEM_MENU":
        draft.itemsMenu =
          draft.itemsMenu.includes(action.payload.template) > -1
            ? [...draft.itemsMenu, action.payload.template]
            : draft.itemsMenu;
        break;
      case "@auth/SIGN_OUT": {
        draft.modalUser = false;
        draft.modalEmail = false;
        draft.tenantProfile = "";
        draft.itemsMenu = ["all"];
        break;
      }
      default:
    }
  });
}
