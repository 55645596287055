import styled from "styled-components";

export const Wrapper = styled.div`
  .nav-link {
    font-weight: 500;
    font-size: 16px;
    color: #333;
    border-bottom: 1px #eae6e6 solid;
    padding: 12px 3px;
  }
  .active {
    color: #007bff;
  }
`;
