import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Input, Select } from "@rocketseat/unform";

import { OverlayTrigger, Tooltip, Row, Col, Button } from "react-bootstrap";

import logo from "~/assets/LogoBranco.png";

import {
  signInRequestDoador,
  signInRequestAluno,
  signInRequestAdmin,
} from "~/store/modules/auth/actions";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
import { MdInfoOutline } from "react-icons/md";
import { toast } from "react-toastify";

export default function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  const [perfil, setPerfil] = useState("");

  function handleSubmit(data) {
    const { password, perfil, campus, ra, email } = data;
    switch (perfil) {
      case "aluno":
        dispatch(signInRequestAluno("Hortolândia", ra, password));
        break;
      case "doador":
        dispatch(signInRequestDoador(email, password));
        break;
      case "admin":
        dispatch(signInRequestAdmin(email, password));
        break;
      default:
        toast.error("Perfil não disponível");
        break;
    }
  }

  return (
    <>
      {/* <div
        className="position-absolute w-100"
        style={{ maxWidth: "315px", overflow: "hidden", borderRadius: "13px" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="360"
          height="360"
          style={{ borderRadius: "13px" }}
          viewBox="0 0 200 200"
        >
          <rect fill="#ffffff" width="200" height="200" />
          <defs>
            <linearGradient
              id="a"
              gradientUnits="userSpaceOnUse"
              x1="88"
              y1="88"
              x2="0"
              y2="0"
            >
              <stop offset="0" stopColor="#064e77" />
              <stop offset="1" stopColor="#0a7dbe" />
            </linearGradient>
            <linearGradient
              id="b"
              gradientUnits="userSpaceOnUse"
              x1="75"
              y1="76"
              x2="168"
              y2="160"
            >
              <stop offset="0" stopColor="#8f8f8f" />
              <stop offset="0.09" stopColor="#b3b3b3" />
              <stop offset="0.18" stopColor="#c9c9c9" />
              <stop offset="0.31" stopColor="#dbdbdb" />
              <stop offset="0.44" stopColor="#e8e8e8" />
              <stop offset="0.59" stopColor="#f2f2f2" />
              <stop offset="0.75" stopColor="#fafafa" />
              <stop offset="1" stopColor="#FFFFFF" />
            </linearGradient>
            <filter id="c" x="0" y="0" width="200%" height="200%">
              <feGaussianBlur in="SourceGraphic" stdDeviation="12" />
            </filter>
          </defs>
          <polygon fill="url(#a)" points="0 174 0 0 174 0" />
          <path
            fill="#000"
            fillOpacity=".5"
            filter="url(#c)"
            d="M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z"
          />
          <path
            fill="url(#b)"
            d="M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z"
          />
        </svg>
      </div> */}
      <div
        className="p-4 position-relative"
        style={{ zIndex: 2, backgroundColor: "#003366", borderRadius: "12px" }}
      >
        <img src={logo} height="60px" className="my-3" />
        <h3 className="mt-3 text-light">Login</h3>
        <h6 className="text-light">Entre com seus dados</h6>
        <Form onSubmit={handleSubmit}>
          <Select
            name="perfil"
            placeholder="Selecione seu perfil"
            required
            style={{ backgroundColor: "#f3f2f3" }}
            options={[
              { id: "aluno", title: "Aluno" },
              { id: "doador", title: "Doador" },
              { id: "admin", title: "Administrador" },
            ]}
            onChange={(e) => setPerfil(e.target.value)}
          />
          {perfil === "aluno" ? (
            <Row>
              {/* <Col xs={12}>
                <Select
                  name="campus"
                  placeholder="Selecione seu campus"
                  style={{ width: "100%", backgroundColor:"#f3f2f3" }}
                  required
                  
                  options={[
                    { id: "ec", title: "Engenheiro Coelho" },
                    { id: "ht", title: "Hortolândia" },
                    { id: "sp", title: "São Paulo" },
                  ]}
                />
              </Col> */}
              <Col xs={12}>
                <>
                  <Input
                    name="ra"
                    type="text"
                    style={{ width: "100%", backgroundColor: "#f3f2f3" }}
                    required
                    placeholder="E-mail"
                  />
                  {/* <OverlayTrigger
                    key={"bottom"}
                    placement={"bottom"}
                    overlay={
                      <Tooltip id={`tooltip-${"bottom"}`}>
                        Apenas números
                      </Tooltip>
                    }
                  >
                    <MdInfoOutline
                      color="#07537e"
                      size={18}
                      style={{
                        marginLeft: "4%",
                        cursor: "pointer",
                        position: "absolute",
                        right: "-7px",
                        height: "44px",
                      }}
                    />
                  </OverlayTrigger> */}
                </>
              </Col>
              <Col xs={12}>
                <>
                  <Input
                    name="password"
                    type="password"
                    style={{ width: "100%", backgroundColor: "#f3f2f3" }}
                    required
                    placeholder="Senha"
                  />
                  {/* <OverlayTrigger
                    key={"bottom"}
                    placement={"bottom"}
                    overlay={
                      <Tooltip id={`tooltip-${"bottom"}`}>
                        Senha portal do aluno
                      </Tooltip>
                    }
                  >
                    <MdInfoOutline
                      color="#07537e"
                      size={18}
                      style={{
                        marginLeft: "4%",
                        cursor: "pointer",
                        position: "absolute",
                        right: "-7px",
                        height: "44px",
                      }}
                    />
                  </OverlayTrigger> */}
                </>
              </Col>
            </Row>
          ) : perfil === "doador" || perfil === "admin" ? (
            <>
              <Input
                name="email"
                type="email"
                required
                placeholder="E-mail"
                style={{ backgroundColor: "#f3f2f3" }}
              />
              <Input
                style={{ backgroundColor: "#f3f2f3" }}
                name="password"
                type="password"
                required
                placeholder="Senha"
              />
            </>
          ) : (
            <></>
          )}

          <Button id="loginBtn" type="submit">
            {loading ? "Carregando..." : "Acessar"}
          </Button>
          <Link to="/register" style={{ color: "#f3f2f3" }}>
            Cadastrar
          </Link>
          <Link to="/" style={{ color: "#f3f2f3" }}>
            <IoMdArrowBack size={16} /> Página Inicial
          </Link>
          <Link to="/recovery" style={{ color: "#f3f2f3" }}>
            Esqueci a senha <IoMdArrowForward size={16} />
          </Link>
        </Form>
      </div>
    </>
  );
}
