const JsonMenu = [
  { url: "/", 
    person: "all", 
    label: "Home" 
  },

  { url: "/sobre/oprograma", 
    person: "all", 
    label: "Sobre o programa" 
  },

  {
    url: "/sobre/ounasp",
    person: "all",
    label: "O Unasp",
  },

  {
    url: "/dashboard",
    person: "donor",
    label: "Minhas doações",
  },

  {
    url: "/dashboard",
    person: "student",
    label: "Minha área",
  },
  
  {
    url: "/admin/home",
    person: "admin",
    label: "Painel de controle",
  },
];

export default JsonMenu;
