import styled from "styled-components";

export const Wrapper = styled.div`
  .logo {
    height: 50px;
    padding: 4px;
    margin-right: 30px;
  }

  .imgProfile {
    width: 40px;
    height: 40px;
  }

  a {
    font-weight: bold;
    color: #7159c1;
    margin-right: 25px;
    /* text-decoration: none;
    &:hover {
      transition: transform 0.35s cubic-bezier(0.52, 0.01, 0.16, 1);
      transition: color 0.2s ease;
      border-bottom: 2px solid #233b62;
    } */
  }
  #menuTop {
    position: fixed;
    width: 100%;
    z-index: 9998;
    top: 0px;
  }
  #dropDownMenu {
    a {
      color: #000;
      font: 14px "Roboto", sans-serif;
      font-weight: 300;
      padding: 8px 15px;
      transition: 0.7s;

      &:hover {
        background: #233b62;
        color: #fff;
      }
    }
    button {
      background: transparent;
      border: transparent;
      box-shadow: none;
      &:hover {
        background: transparent;
        border: transparent;
      }
      &:active {
        background: transparent;
        border: transparent;
      }
    }
  }
`;

export const MenuMobile = styled.div`
  z-index: 9999;
  position: fixed;
  width: 100%;
  top: 65px;
  display: ${(props) => (props.visible ? "block" : "none")};

  #listItems {
    z-index: 9999;
    position: fixed;
    width: 100%;
    top: 65px;
    a {
      padding: 6px;
    }
  }
`;

export const Container = styled.div`
  background: #fff;
  padding: 0 30px;
`;
export const Content = styled.div`
  height: 64px;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .dropbtn {
    background-color: #ffffff;
    color: #1d1d1b;
    padding: 16px;
    font-size: 16px;
    border: 1px solid #e6e6e6;
    cursor: pointer;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content a {
    padding: 12px 16px;
    text-decoration: none;
    display: block;

    font-weight: bold;
    color: #7159c1;
    margin-right: 0;
  }

  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown:hover .dropbtn {
    background-color: #e8e5e5;
  }

  .logo {
    height: 44px;
  }

  nav {
    display: flex;
    align-items: center;
    img {
      margin-right: 20px;
      padding-right: 20px;
      border-right: 1px solid #eee;
    }
    a {
      font-weight: bold;
      color: #7159c1;
      margin-right: 25px;
    }
  }

  aside {
    display: flex;
    align-items: center;
  }
`;
export const Profile = styled.div`
  display: flex;
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid #eee;

  div {
    text-align: right;
    margin-right: 10px;
    strong {
      display: block;
      color: #333;
    }
    a {
      display: block;
      margin-top: 2px;
      font-size: 12px;
      color: #999;
    }
  }
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
`;
