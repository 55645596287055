import { createGlobalStyle } from "styled-components";

import "react-toastify/dist/ReactToastify.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus{
    outline: 0;
  }

  html, body, #root{
    background: #ededed;
    height: 100%;
  }

  body{
    -webkit-font-smoothing: antialiased;
  }

  body, input, button{
    font: 14px 'Roboto', sans-serif;
  }

  a { 
    text-decoration: none;
  }
  
  ul{
    list-style: none;
  }

  button{
    cursor: pointer;
  }

  .carousel-item {
    max-height: 460px;
    overflow: hidden;

    img {
      transform: translateY(0%);
      @media (max-width: 768px) {
        height: 50vh;
        width: auto !important;
      }
    }
  }

  .contentcenter {
    display: flex;
    justify-content: center;
    align-items: center;

    .spinner-border{
      margin-right: 15px;
    }
  }

  .react-confirm-alert-overlay {
    z-index: 9999;
    background: #2f2f2fe6;
  }


  .imagePeople {
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0.3);
    background: #eee;
    
    border-radius: 0;
    width: auto;
    margin: 0 auto;
    height: 175px;
  }

  .divImage {
    border-radius: 50%;
    width: 157px;
    overflow: hidden;
    height: 160px;
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  

  #preloadimage {
    position: absolute;
    width: 60px;
    height: 60px;
    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
  
  }
  #preloadlogo {
    position: absolute;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    margin: 4px;
    background: "#ccc";
  }

  @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
  @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }


  .bshadow {
    /*box-shadow: #6d6262 0px 0px 21px -6px;*/
    box-shadow: #6d6262 0px 0px 25px -10px;
  }

  #dropdown-button-icon {
    background: transparent;
    border: transparent;
    box-shadow: none;
    &:after {
      display: none;
    }
  }

  #listItems {
    z-index: 9999;
    position: fixed;
    width: 100%;
    top: 65px;
    
    a {
      color: #333;
      padding: 8px 20px;
    }
    .active {
      color: #007bff;
    }
  }

  .switchBox {
    display: flex;
    align-items: center;
    span {
      margin: 0px 9px;
    }
  }

  .title{
    /* border: 0px solid #5bc0de; */
    border-left-width: .25rem;
    border-right-width: .25rem;
    border-radius: .25rem;
    h1{
      font-weight: 300;
      font-size: 1.8rem;
      text-shadow: #666 0px 1px 2px;
      font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    }
  }

  .inputSearch {
    &:focus {
      box-shadow: none;
    }
  }

  #box {
    position: fixed;
    height: 100%;
    z-index: 998;
    background: #000000bf;
    width: 100%;
  }

  .lead {
    font-size: 1.16rem;
    line-height: 2rem;
    font-weight: 300;
  }

  .cover-container {
    max-width: 47em;
  }

  .cover-heading{
    color: #f1934f;
  }





  /* Lucas Santos */

  /* header */
  #headerLinks{
    color: #496be6;
    text-decoration: none;    
  } 
  #headerLinks:hover {
    color: #2a99ed;
  }

  /* Body */
  #bodyBtn{
    background-color: #496be6;
    border-radius: 10px;
    border: 0px;    
  }
  #bodyBtn:hover{
    background-color: #2a99ed;    
  }

  /* Modal */
  .modalBtn{
    background-color: #496be6;
    border-radius: 10px;
    border: 0px;
    font-size: 18px;
  }
  .modalBtn:hover{
    background-color: #2a99ed;
  }
  #loginBtn{
    background-color: #435e99;
  }
  #loginBtn:hover{
    background-color: #31407a;
  }
  /* Banner Img and Button*/
  #bannerImg{
    width: 100%;
    height: auto;
  }
  #bannerBtn{
    position: absolute;
    background-color: #496be6;
    color: white;    
    font-weight: bold;    
    border: none;
    cursor: pointer;
    border-radius: 50px;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3);
              /* x | y | blur-radius | radius | color */
  }
  #bannerBtn:hover{
    background-color: #2a99ed;
    transition: transform .2s;
    transform: scale(1.03);
  }
  /* Responsividade Botao "Doe Agora" */
  @media only screen and (max-width: 299px){
    #bannerBtn{
      top: 10%;
      left: 65%;
      font-size: 14px;
      padding: 12px 12px;
    }
    #bannerImg{
      margin-top: 36px;
    }
  }
  @media only screen and (min-width: 300px){
    #bannerBtn{
      top: 12%;
      left: 70%;
      font-size: 14px;
      padding: 12px 12px;
    }
    #bannerImg{
      margin-top: 36px;
    }
  }
  @media only screen and (min-width: 350px){
    #bannerBtn{
      top: 15%;
      left: 73%;
      font-size: 14px;
      padding: 12px 12px;
    }
    #bannerImg{
      margin-top: 36px;
    }
  }
  @media only screen and (min-width: 400px){
    #bannerBtn{
      top: 15%;
      left: 73%;
      font-size: 16px;
      padding: 12px 12px;
    }
    #bannerImg{
      margin-top: 36px;
    }
  }
  @media only screen and (min-width: 500px){
    #bannerBtn{
      top: 17%;
      left: 73%;
      font-size: 18px;
      padding: 12px 24px;
    }
    #bannerImg{
      margin-top: 38px;
    }
  }
  @media only screen and (min-width: 600px){
    #bannerBtn{
      top: 20%;
      left: 75%;
      font-size: 18px;
      padding: 12px 24px;
    }
    #bannerImg{
      margin-top: 38px;
    }
  }  
  @media only screen and (min-width: 700px){
    #bannerBtn{
      top: 25%;
      left: 75%;
      font-size: 20px;
      padding: 12px 36px;
    }    
  }
  @media only screen and (min-width: 900px) {
    #bannerBtn{
      top: 25%;
      left: 75%;
      font-size: 20px;
      padding: 12px 36px;
    }
  }
  @media only screen and (min-width: 1025px) {
    #bannerBtn{
      top: 30%;
      left: 75%;
      font-size: 24px;   
      padding: 12px 48px;   
    }
  }
  @media only screen and (min-width: 1441px) {
    #bannerBtn{
      top: 40%;
      left: 75%;
      font-size: 36px;
      padding: 12px 48px;
    }
  }
  @media only screen and (min-width: 1921px) {
    #bannerBtn{
      top: 45%;
      left: 75%;
      font-size: 48px; 
      padding: 12px 48px;     
    }
  }
`;
