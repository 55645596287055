import React, { useState } from "react";
import Slider from "@material-ui/lab/Slider";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import { getOrientation } from "get-orientation/browser";
import { getRotatedImage } from "./rotateImage";
import "./styles.css";
import { Button } from "@material-ui/core";

import { useDispatch } from "react-redux";

import { Wrapper } from "./styled";

import imagePreLoading from "~/assets/imagePreLoading.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { toast } from "react-toastify";
import { updateImageProfile } from "~/store/modules/user/actions";
import URLApi from "~/config/URLApi";

import api from "~/services/api";
// import { Container } from './styles';

export default function CropImage({ data, route }) {
  const { url } = data;
  const dispatch = useDispatch();
  const ORIENTATION_TO_ANGLE = {
    "3": 180,
    "6": 90,
    "8": -90,
  };

  //const [file, setFile] = useState(id);
  const [preview, setPreview] = useState(url);
  const [loaded, setloaded] = useState(true);

  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });

  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [aspect] = useState(1 / 1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  //const [croppedImage, setCroppedImage] = useState(null);
  const [isCropping, setIsCropping] = useState(false);

  function onCropChange(crop) {
    setCrop(crop);
  }

  function onCropComplete(croppedArea, croppedAreaPixels) {
    //console.log(croppedArea, croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels);
  }

  function onRotationChange(rotation) {
    setRotation(rotation);
  }
  function onZoomChange(zoom) {
    setZoom(zoom);
  }

  async function showResult() {
    try {
      setIsCropping(true);
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );

      let res = await fetch(croppedImage);
      let d = await res.blob();
      let file = new File([d], "test.jpg", {
        type: "image/jpeg",
      });

      const data = new FormData();
      data.append("file", file);

      const response = await api.post(route, data);
      const dt = response.data;

      if (dt.status) {
        const { imageId } = dt.entity;
        //setFile(imageId);
        setPreview(`${URLApi}/image/${imageId}`);

        toast.success(`Imagem atualizada com sucesso`);
        dispatch(
          updateImageProfile({
            id: imageId,
            url: `${URLApi}/image/${imageId}`,
          })
        );
      } else {
        toast.error(`Ocorreu um erro ao atualizar a foto. ${dt.message}`);
      }

      setImageSrc(null);
      onClose();
      //setCroppedImage(croppedImage);
      setIsCropping(false);
    } catch (e) {
      //console.error(e.response);
      toast.error("Imagem inválida. Selecione outra foto");
      setIsCropping(false);
    }
  }

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  async function onClose() {
    //setCroppedImage(null);
  }

  async function onFileChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      // apply rotation if needed
      const orientation = await getOrientation(file);
      const rotation = ORIENTATION_TO_ANGLE[orientation];
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
      }

      setImageSrc(imageDataUrl);
      setCrop({ x: 0, y: 0 });
      setZoom(1);
    }
  }

  return (
    <>
      <Wrapper>
        <label>
          <div className="divImage">
            <img
              src={imagePreLoading}
              id="preloadimage"
              style={{ display: !!loaded ? "block" : "none" }}
            />
            <LazyLoadImage
              effect="blur"
              src={
                preview ||
                "https://api.adorable.io/avatars/70/abott@adorable.png"
              }
              afterLoad={() => setloaded(false)}
              visibleByDefault={preview.src === imagePreLoading}
              className="imagePeople text-center"
            />
          </div>
          <input
            type="file"
            id="avatar"
            accept="image/*"
            onChange={onFileChange}
          />
        </label>
      </Wrapper>

      <div className="componentApp">
        {imageSrc && (
          <div>
            <div className="crop-container">
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                aspect={aspect}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={onZoomChange}
                onRotationChange={onRotationChange}
              />
            </div>
            <div className="controls">
              Zoom
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => onZoomChange(zoom)}
                classes={{ container: "slider" }}
              />
            </div>
            <div className="controls">
              Rotação
              <Slider
                value={rotation}
                min={0}
                max={360}
                step={90}
                aria-labelledby="Rotação"
                onChange={(e, rotation) => onRotationChange(rotation)}
                classes={{ container: "slider" }}
              />
            </div>
            <div className="button">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setImageSrc("")}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={showResult}
                disabled={isCropping}
              >
                {isCropping ? "Atualizando..." : "Atualizar imagem"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
