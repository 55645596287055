import styled from "styled-components";

export const Wrapper = styled.div`
  .tableList {
    min-height: 300px;
    tbody {
      tr {
        height: 25px;
      }
    }
  }
`;
