import styled from "styled-components";

export const Wrapper = styled.div`
  .tableList {
    td,
    th {
      vertical-align: baseline;
    }

    /* .tdControl {
      display: flex;
      justify-content: space-around;
      align-items: center;
    } */
  }
`;
