import styled from "styled-components";

export const Wrapper = styled.div`
  .donations .card {
    width: 100%;
  }

  /* .carousel-item img {
    transform: translateY(0%);
  } */
`;
