import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import history from "~/services/history";

import Header from "~/pages/__layouts/admin/Components/Header";
import Sidebar from "~/pages/__layouts/admin/Components/Sidebar";

import { Wrapper } from "./styles";

export default function AdminLayout({ children }) {
  const tenantProfile = useSelector((state) => state.header.tenantProfile);

  /* middelware */
  let location = useLocation();
  const template = useSelector((state) => state.user.template);
  const tenantsAllowed = ["admin"];
  const root = "admin";

  useEffect(() => {
    if (
      location.pathname.includes(root) &&
      tenantsAllowed.indexOf(template) === -1
    ) {
      history.push("/");
    }
  }, [location]);
  /* end middleware */

  return (
    <Wrapper>
      <Header />
      {!!tenantProfile && (
        <div className="container-fluid">
          <div className="row">
            <nav className="col-md-2 d-none d-md-block bg-light ">
              <Sidebar />
            </nav>
            <div
              role="main"
              className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4"
            >
              {children}
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
}

AdminLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
