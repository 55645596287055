import styled from "styled-components";
import { darken } from "polished";

export const Wrapper = styled.div`
  height: 100%;
  /*background: #df8144;*/
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  /* background: #9c9c9c; */
  background: #fafafa;
  width: 100%;
  max-width: 315px;
  text-align: center;
  /* padding: 28px; */
  border-radius: 12px;
  box-shadow: 0px 6px 8px 0px #afa7a7;

  .logo {
    color: #fff;
    font-size: 80px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    input,
    select {
      background: rgba(255, 255, 255, 0.85);
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #000;
      margin: 0 0 10px;
      font-size: 1.2em;

      option {
        color: #000;
        font-size: 16px;
      }

      &::placeholder {
        color: #929292;
      }
    }

    span {
      color: #fb6f91;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }

    label {
      font-size: 22px;
      color: #fff;
    }

    button {
      margin: 5px 0 0;
      height: 44px;
      background: #546b82;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.03, "#3b9eff")};
      }
    }

    a {
      /* color: #fff; */
      color: #054970;
      margin-top: 15px;
      font-size: 16px;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }
`;
