import styled from "styled-components";

export const ImageBackground = styled.div`
  height: calc(100vh - 48px);
  background: #ffffff
    url("https://cdn1.unasp.br/home/2020/04/17135807/doe-unasp.png") no-repeat
    center top fixed;
  position: fixed;
  width: 100%;
`;

export const Wrapper = styled.div`
  /*!
 * Start Bootstrap - Landing Page v5.0.7 (https://startbootstrap.com/template-overviews/landing-page)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-landing-page/blob/master/LICENSE)
 */

  body {
    font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;
  }

  header.masthead {
    position: relative;
    background-color: #343a40;
    /* background: url("../img/bg-masthead.jpg") no-repeat center center; */
    background-size: cover;
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  header.masthead .overlay {
    position: absolute;
    background-color: #212529;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.3;
  }

  header.masthead h1 {
    font-size: 2rem;
  }

  @media (min-width: 768px) {
    header.masthead {
      padding-top: 12rem;
      padding-bottom: 12rem;
    }
    header.masthead h1 {
      font-size: 3rem;
    }
  }

  .showcase .showcase-text {
    padding: 3rem;
  }

  .showcase .showcase-img {
    min-height: 30rem;
    background-size: cover;
  }

  @media (min-width: 768px) {
    .showcase .showcase-text {
      padding: 7rem;
    }
  }

  .features-icons {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .features-icons .features-icons-item {
    max-width: 20rem;
  }

  .features-icons .features-icons-item .features-icons-icon {
    height: 7rem;
  }

  .features-icons .features-icons-item .features-icons-icon i {
    font-size: 4.5rem;
  }

  .features-icons .features-icons-item:hover .features-icons-icon i {
    font-size: 5rem;
  }

  .testimonials {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .testimonials .testimonial-item {
    max-width: 18rem;
  }

  .testimonials .testimonial-item img {
    max-width: 12rem;
    box-shadow: 0px 5px 5px 0px #adb5bd;
  }

  .call-to-action {
    position: relative;
    background-color: #343a40;
    /* background: url("../img/bg-masthead.jpg") no-repeat center center; */
    background-size: cover;
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .call-to-action .overlay {
    position: absolute;
    background-color: #212529;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.3;
  }

  footer.footer {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .style1 {
    background: #697f8c;
    color: #fff;
  }
  .style2 {
    background: #117a8b;
    color: #fff;
  }
  .style3 {
    background: #028ab8;
    color: #fff;
    .btn {
      background-color: #0ba1a3;
      border-color: #0ba1a3;
    }
  }
`;
