import styled from "styled-components";

export const Wrapper = styled.div`
  padding-top: 45px;
  /*min-height: 100%;
  padding-bottom: 3%;
  background: linear-gradient(-90deg, #7159c1, #ab59c1);*/
`;

export const Footer = styled.footer`
  background-color: #f5f5f5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #666;
  font-weight: 400;
  text-align: center;
`;
