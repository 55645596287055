export function updateModalProfile() {
  return {
    type: "@header/TOOGLE_MODAL_USER",
  };
}

export function changeEmail() {
  return {
    type: "@header/TOOGLE_MODAL_EMAIL",
  };
}

export function updateTenantProfile(tenant) {
  return {
    type: "@header/UPDATE_TENANT_PROFILE",
    payload: { tenant },
  };
}

export function setItemMenuProfile(template) {
  return {
    type: "@header/SET_ITEM_MENU",
    payload: { template },
  };
}
