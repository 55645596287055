import React, { useRef, useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";

import api from "~/services/api";
import URLApi from "~/config/URLApi";

import { toast } from "react-toastify";
import { Wrapper } from "./styles";

import PaginationComponent from "react-js-pagination";
import CardStudentPage from "~/components/CardStudentPage";

import { format } from "date-fns";
import pt from "date-fns/locale/pt";

import { MdClear } from "react-icons/md";
import { IoMdSearch } from "react-icons/io";
import {
  Container,
  Row,
  Col,
  Button,
  Spinner,
  InputGroup,
  FormControl,
  Form,
} from "react-bootstrap";

export default function DonationsCampaign() {
  const { campaignId } = useParams();
  const formRef = useRef(null);
  const searchRef = useRef(null);

  const [infoCampaign, setInfoCampaign] = useState(null);
  const [donations, setDonations] = useState([]);

  const [searching, setSearching] = useState(true);

  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  const [searchString, setSearchString] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(12);
  const [totalItens, setTotalItens] = useState(0);

  const [timeout, setTimeOut] = useState(0);

  const lenghtSeachString = useMemo(() =>
    searchString.length > 0 ? true : false
  );

  useEffect(() => {
    async function loading() {
      const response = await api.get(`/public/getcampaigninfo/${campaignId}`);
      const dt = response.data;

      setInfoCampaign({
        ...dt,
        startDate: dt.startDate
          ? format(new Date(dt.startDate), "dd 'de' MMM 'de' yyyy HH:mm", {
              locale: pt,
            })
          : null,
        endDate: dt.endDate
          ? format(
              new Date(dt.endDate),
              "dd/MM/yyyy HH:mm",
              "dd 'de' MMM 'de' yyyy HH:mm",
              {
                locale: pt,
              }
            )
          : null,
      });
    }
    loading();
  }, []);

  useEffect(() => {
    setActivePage(1);
  }, [searchString]);

  useEffect(() => {
    async function onInit() {
      setLoading(true);
      setDonations([]);
      try {
        setMessage("Carregando...");
        const response = await api.get(
          searchString === ""
            ? `/public/getcampaigndonationpagecards/${campaignId}/${activePage}/${itemPerPage}`
            : `/public/findstudentdonationpagecard/${searchString}/${campaignId}/${activePage}/${itemPerPage}`
        );

        if (response.status == 200) {
          const numCaracter = 120;
          const data = response.data;

          setTotalItens(data.indexSize);

          if (data.entities.length > 0) {
            setDonations(
              data.entities.map((x) => ({
                ...x,
                textSplit: `${x.pageText.substring(0, numCaracter)} ${
                  x.pageText.length > numCaracter ? "..." : ""
                }`.trim(),
                urlImage: !!x.imageId ? `${URLApi}/image/${x.imageId}` : null,
              }))
            );

            setMessage("");
            setLoading(false);
          } else {
            setMessage("Não encontramos nenhum item");
          }
        } else {
          toast.error("Ocorreu um erro ao carregar os itens.. tente novamente");
        }
      } catch (error) {
        setMessage("Erro ao carregar a página.. tente novamente");
      }
    }
    onInit();
  }, [activePage, searchString]);

  useEffect(() => {
    if (searching) {
      searchRef.current.focus();
    }
  }, [searching]);

  const handleFind = (e) => {
    const vl = e.target.value;

    if (timeout) clearTimeout(timeout);
    setTimeOut(
      setTimeout(() => {
        setSearchString(vl);
      }, 400)
    );
  };

  const handleClear = () => {
    setSearchString("");
    formRef.current.reset();
    searchRef.current.focus();
    //setSearching(false);
  };

  const handleSearch = () => {
    setSearching(true);
  };

  const searchFunction = (e) => {
    e.preventDefault();
  };

  return (
    <Wrapper>
      <Container>
        <Row className="donations d-flex justify-content-center mt-5">
          <Col xs={10} sm={12}>
            {!!infoCampaign && (
              <div className="row bg-light no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div className="col p-4 d-flex flex-column position-static">
                  <h3 className="mb-2">{infoCampaign.name}</h3>
                  <div className="mb-1 text-muted">
                    {infoCampaign.startDate &&
                      `Início: ${infoCampaign.startDate}`}
                  </div>
                  <div className="mb-3 text-muted">
                    {infoCampaign.endDate && `Fim: ${infoCampaign.endDate}`}
                  </div>
                  <p className="card-text mb-auto">
                    {infoCampaign.description}
                  </p>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row className="d-flex justify-content-center mb-4">
          <Col xs={11} sm={8}>
            {!!searching ? (
              <Form
                ref={formRef}
                style={{ width: "100%" }}
                onSubmit={searchFunction}
              >
                <InputGroup className="">
                  <FormControl
                    type="text"
                    placeholder="Procurar aluno"
                    className="form-control inputSearch borderRadiusLeft"
                    onChange={handleFind}
                    ref={searchRef}
                  />
                  <InputGroup.Append style={{ borderLeft: "none" }}>
                    <>
                      {lenghtSeachString && (
                        <Button
                          variant="outline-secontary"
                          className="borderAndBackground"
                          onClick={handleClear}
                        >
                          <MdClear color="red" />
                        </Button>
                      )}
                      <Button
                        variant="outline-secondary"
                        className="borderRadiusRight borderAndBackground"
                        disabled
                      >
                        <IoMdSearch size={20} />
                      </Button>
                    </>
                  </InputGroup.Append>
                </InputGroup>
              </Form>
            ) : (
              <Button
                variant="outline-info"
                onClick={handleSearch}
                title="Clique para procurar"
              >
                <IoMdSearch />
              </Button>
            )}
          </Col>
        </Row>
        {loading ? (
          <Row>
            <Col>
              <div className="contentcenter mt-5">
                {message == "Carregando..." && <Spinner animation="border" />}{" "}
                <h4>{message}</h4>
              </div>
            </Col>
          </Row>
        ) : (
          <Row className="d-flex justify-content-center">
            {donations.map((x, index) => (
              <CardStudentPage key={index} data={x} />
            ))}
          </Row>
        )}
      </Container>
      <Container
        hidden={message.toLowerCase().trim() === "carregando..." ? true : false}
        className="mt-5"
      >
        <Row
          className={`justify-content-center ${
            totalItens <= itemPerPage ? "d-none" : "d-flex"
          }`}
        >
          <Col xs={10} sm={5} className="d-flex justify-content-center">
            <PaginationComponent
              itemClass="page-item"
              linkClass="page-link"
              activePage={activePage}
              itemsCountPerPage={itemPerPage}
              totalItemsCount={totalItens}
              pageRangeDisplayed={5}
              onChange={(e) => setActivePage(e)}
            />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}
