import React, { useEffect, useState } from "react";
import api from "~/services/api";

import { useDispatch, useSelector } from "react-redux";
import {
  acceptRegulationRequest,
  updateEmailConfirmation,
} from "~/store/modules/user/actions";
import { signOut } from "~/store/modules/auth/actions";
import { changeEmail } from "~/store/modules/header/actions";

import {
  Button,
  Card,
  Container,
  Modal,
  Row,
  Col,
  Jumbotron,
} from "react-bootstrap";

import { Form, Input, Select } from "@rocketseat/unform";

import { isBefore, parseISO } from "date-fns";
import { toast } from "react-toastify";

import Student from "~/pages/Dashboard/Student";
import Donor from "~/pages/Dashboard/Donor";
import { Wrapper } from "./styles";

export default function Dashboard() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  const [modalTermUse, setModalTermUse] = useState(false);
  const [termoDeUso, setTermoDeUso] = useState({});

  //const [modalNewEmail, setModalNewEmail] = useState(false);

  const profile = useSelector((state) => state.user.profile);
  const template = useSelector((state) => state.user.template);

  const tenantsAllowed = ["donor", "student"];

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        setMessage("Carregando...");
        if (!profile.regulationAccepted) {
          if (tenantsAllowed.indexOf(template) !== -1) {
            const response = await api.get(
              template === "student"
                ? `/studentarea/getstudentregulation`
                : `/giverarea/getgiverregulation`
            );
            const dt = response.data;

            setModalTermUse(true);
            setTermoDeUso(dt);
          }

          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setMessage("Erro ao carregar a página.. tente novamente");
      }
    }

    /* if (
      !profile.emailConfirmed &&
      JSON.stringify(profile.updateEmail) === "{}"
    ) {
      setModalNewEmail(true);
    }
 */
    loadData();
  }, []);

  const handleAcceptTerm = async () => {
    dispatch(acceptRegulationRequest(template));
    setModalTermUse(false);
  };

  const renderComponent = (template, profile) => {
    switch (template) {
      case "student":
        if (
          !profile.emailConfirmed ||
          (JSON.stringify(profile.updateEmail) !== "{}" &&
            !profile.updateEmail.newEmailConfirmed)
        ) {
          return renderConfirmEmail({ profile });
        } else {
          return <Student profile={profile} />;
        }
      case "donor":
        return <Donor profile={profile} />;
      default:
        break;
    }
  };

  const renderConfirmEmail = ({ profile }) => {
    const handleReSendEmail = async () => {
      const response = await api.put(`/authentication/resendconfirmationemail`);

      const { message, status, entity } = response.data;
      if (status) {
        dispatch(updateEmailConfirmation(entity));
        toast.success(
          "Link enviado. Se não chegar, verifique em sua caixa de spam"
        );
      } else {
        toast.error(message);
      }
    };
    const handleCancelUpdateEmail = async () => {
      const response = await api.delete(`/authentication/cancelemailupdate`);

      const { message, status, entity } = response.data;
      if (status) {
        toast.success("Atualização cancelada. Entre novamente");
        window.setTimeout(() => {
          window.location = "/logout";
        }, 3000);
      } else {
        toast.error(message);
      }
    };

    const { updateEmail, emailConfirmed, email } = profile;
    const { newEmail, newEmailConfirmed, codeExpiration } = updateEmail;

    return (
      <>
        <Container>
          <Row className="d-flex justify-content-center">
            <Col xs={12} md={12} className="mb-4">
              <Jumbotron>
                <h1>Confirme seu e-mail para continuar</h1>
                {!!newEmail ? (
                  <>
                    <p className="h6">
                      Um link foi enviado para{" "}
                      <span className="font-weight-bolder">{newEmail}</span>
                    </p>

                    <p className="h5">
                      {isBefore(parseISO(codeExpiration), new Date()) &&
                        "Seu token já expirou. "}
                      Para receber o link de confirmação novamente{" "}
                      <Button
                        variant="success"
                        className="btn-sm"
                        onClick={handleReSendEmail}
                      >
                        clique aqui
                      </Button>
                    </p>
                  </>
                ) : (
                  <p className="h5">
                    Você precisa cadastrar um e-mail para continuar{" "}
                    <Button
                      variant="success"
                      className="btn-sm"
                      onClick={() => dispatch(changeEmail())}
                    >
                      Cadastrar
                    </Button>
                  </p>
                )}

                {emailConfirmed && (
                  <p>
                    <Button variant="danger" onClick={handleCancelUpdateEmail}>
                      Cancelar Atualização
                    </Button>
                  </p>
                )}
              </Jumbotron>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  return (
    <Wrapper className="py-5">
      {loading ? (
        <Container>
          <h3>{message}</h3>
        </Container>
      ) : (
        !modalTermUse && renderComponent(template, profile)
      )}

      {/* Modal Termo de Uso */}
      <Modal
        size="xl"
        show={modalTermUse}
        className="my-5"
        aria-labelledby="example-modal-sizes-title-lg"
        backdrop="static"
      >
        <Modal.Body>
          <Card bg="dark" text="white">
            <Card.Header>
              <h4>Termo de uso</h4>
            </Card.Header>
            <Card.Body>
              <Card.Text
                className="h6 text-justify"
                dangerouslySetInnerHTML={{
                  __html: termoDeUso.text || "",
                }}
              ></Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button
                variant="primary"
                onClick={() => handleAcceptTerm()}
                block
              >
                Li e aceito os termos
              </Button>
            </Card.Footer>
          </Card>
        </Modal.Body>
      </Modal>
    </Wrapper>
  );
}
