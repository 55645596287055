import { formatPrice } from "~/util/format";
import { format, addDays } from "date-fns";
import explodeDate from "~/util/explodeDate";

export default function parseDataBradesco(data) {
  const responsePay = JSON.parse(data);
  const entity = {
    nuTituloGerado: responsePay.nuTituloGerado,
    //nuCnpjBeneficiario: responsePay.nuCnpjBeneficiario,
    nomeBeneficiario: responsePay.nomeBeneficiario,
    logradouroBeneficiario: responsePay.logradouroBeneficiario,
    nuLogradouroBeneficiario: responsePay.nuLogradouroBeneficiario,
    complementoLogradouroBeneficiario:
      responsePay.complementoLogradouroBeneficiario,
    cepBeneficiario: `${responsePay.cepBeneficiario}-${responsePay.cepComplementoBeneficiario}`,
    municipioBeneficiario: responsePay.municipioBeneficiario,
    ufBeneficiario: responsePay.ufBeneficiario,
    nomePagador: responsePay.nomePagador,
    cpfcnpjPagador: `${responsePay.cpfcnpjPagador.substr(
      0,
      9
    )}${responsePay.cpfcnpjPagador.substr(13, 2)}`,
    enderecoPagador: responsePay.enderecoPagador,
    bairroPagador: responsePay.bairroPagador,
    municipioPagador: responsePay.municipioPagador,
    ufPagador: responsePay.ufPagador,
    cepPagador: `${responsePay.cepPagador}-${responsePay.cepComplementoPagador}`,
    dtEmissao: explodeDate(responsePay.dtEmissao, 2),
    dtVencimentoBoleto: explodeDate(responsePay.dtVencimentoBoleto, 1),
    dtLimitePagamentoBoleto: explodeDate(
      responsePay.dtLimitePagamentoBoleto,
      1
    ),
    vlTitulo: responsePay.vlTitulo,
    cdBarras: responsePay.cdBarras,
    linhaDigitavel: responsePay.linhaDigitavel,
  };

  /* cdBarras: responsePay.linhaDigitavel != "99999.99999 99999.999999 99999.999999 9 99999999999999" ? responsePay.cdBarras : "23796822800000005003368160104000000300052200", */

  return {
    ...entity,
    priceFormatted: formatPrice(parseInt(entity.vlTitulo) / 100),
    dtEmissao: format(addDays(new Date(entity.dtEmissao), 1), "dd/MM/yyyy"),
    dtLimitePagamentoBoleto: format(
      addDays(new Date(entity.dtLimitePagamentoBoleto), 1),
      "dd/MM/yyyy"
    ),
    dtVencimentoBoleto: format(
      addDays(new Date(entity.dtVencimentoBoleto), 1),
      "dd/MM/yyyy"
    ),
  };
}
