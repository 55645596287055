import { takeLatest, call, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import URLApi from "~/config/URLApi";

import imgUser from "~/assets/user-alt-solid.png";

import history from "~/services/history";
import api from "~/services/api";
import { signInSuccess, signFailure } from "./actions";

function* signInDonor({ payload }) {
  try {
    const { email, password } = payload;
    const response = yield call(api.post, "Authentication/GiverLogin", {
      useremail: email,
      password,
    });

    const { token, user, authenticated } = response.data;
    if (authenticated) {
      //const tokenParse = JSON.parse(atob(token.split(".")[1]));
      api.defaults.headers.Authorization = `Bearer ${token}`;

      const responseUserInfo = yield call(api.get, "giverarea/getgiverinfo");

      const responseEmailConfirmation = yield call(
        api.get,
        "authentication/checkrequestemailupdate"
      );

      const {
        newEmail,
        newEmailConfirmed,
        codeExpiration,
      } = responseEmailConfirmation.data;

      const usr = {
        ...user,
        avatar: {
          ...user.avatar,
          url: !!user.avatar.id ? `${URLApi}/image/${user.avatar.id}` : imgUser,
        },
        ...responseUserInfo.data,
        updateEmail: { newEmail, newEmailConfirmed, codeExpiration },
      };

      yield put(signInSuccess(token, usr, "donor"));
      history.push("/dashboard");
    } else {
      toast.error("Falha na autenticação, verifique seus dados");
      yield put(signFailure());
    }
  } catch (err) {
    toast.error("Falha na autenticação, verifique seus dados");
    yield put(signFailure());
  }
}

function* signInAdmin({ payload }) {
  try {
    const { email, password } = payload;
    const response = yield call(api.post, "authentication/adminlogin", {
      useremail: email,
      password,
    });

    const { token, user, authenticated } = response.data;
    if (authenticated) {
      api.defaults.headers.Authorization = `Bearer ${token}`;

      // const responseUserInfo = yield call(api.get, "giverarea/getgiverinfo");

      const usr = {
        ...user,
        avatar: {
          ...user.avatar,
          url: !!user.avatar.id ? `${URLApi}/image/${user.avatar.id}` : imgUser,
        },
      };

      yield put(signInSuccess(token, usr, "admin"));
      history.push("/admin/home");
    } else {
      toast.error("Falha na autenticação, verifique seus dados");
      yield put(signFailure());
    }
  } catch (err) {
    toast.error("Falha na autenticação, verifique seus dados");
    yield put(signFailure());
  }
}

function* signInStudent({ payload }) {
  try {
    const { campus, ra, password, birthDate } = payload;
    const response = yield call(api.post, "Authentication/StudentLogin", {
      campus,
      ra,
      password,
      birthDate,
    });

    const { token, user, authenticated } = response.data;
    if (authenticated) {
      api.defaults.headers.Authorization = `Bearer ${token}`;

      const responseUserInfo = yield call(
        api.get,
        "StudentArea/GetStudentInfo"
      );

      const responseEmailConfirmation = yield call(
        api.get,
        "authentication/checkrequestemailupdate"
      );

      const {
        newEmail,
        newEmailConfirmed,
        codeExpiration,
      } = responseEmailConfirmation.data;

      const usr = {
        ...user,
        avatar: {
          ...user.avatar,
          url: !!user.avatar.id ? `${URLApi}/image/${user.avatar.id}` : imgUser,
        },
        ...responseUserInfo.data,
        updateEmail: { newEmail, newEmailConfirmed, codeExpiration },
      };

      yield put(signInSuccess(token, usr, "student"));
      history.push("/dashboard");
    } else {
      toast.error("Falha na autenticação, verifique seus dados");
      yield put(signFailure());
    }
  } catch (err) {
    toast.error("Falha na autenticação, verifique seus dados");
    yield put(signFailure());
  }
}

async function signUp({ payload }) {
  try {
    const { name, email, password, confirmPassword } = payload;
    const result = await api.post("/Authentication/CreateGiverUser", {
      name,
      email,
      password,
      confirmPassword,
    });
    if (!result.data.status) {
      toast.error(result.data.message);
      return;
    }
    toast.success(
      "Usuário criado com sucesso, verifique seu e-mail para confirmação",
      {
        autoClose: 10000,
      }
    );
    history.push("/");
  } catch (err) {
    toast.error("Falha no cadastro, verifique seus dados");
    put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.auth;
  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  history.push("/");
}

export default all([
  takeLatest("persist/REHYDRATE", setToken),
  takeLatest("@auth/SIGN_IN_REQUEST_DONOR", signInDonor),
  takeLatest("@auth/SIGN_IN_REQUEST_ADMIN", signInAdmin),
  takeLatest("@auth/SIGN_IN_REQUEST_STUDENT", signInStudent),
  takeLatest("@auth/SIGN_UP_REQUEST", signUp),
  takeLatest("@auth/SIGN_OUT", signOut),
]);
