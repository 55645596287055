import React, { useEffect, useRef, useMemo } from "react";

import { endOfDay, isBefore, addDays } from "date-fns";

import { Content } from "./styles";
import JsBarcode from "jsbarcode";

import logo from "~/assets/bradesco_logo.png";

import { Row, Col, Button, Alert } from "react-bootstrap";
import ReactToPrint from "react-to-print";

import copy from "~/util/copy";

export default function BoletoComponent({ data }) {
  const canPayYet = useMemo(() => {
    const fimDoDiaMaxPagar = endOfDay(
      addDays(
        new Date(data.dtLimitePagamentoBoleto.split("/").reverse().join("-")),
        1
      )
    );

    return isBefore(Date.now(), fimDoDiaMaxPagar);
  });

  useEffect(() => {
    async function loadEvent() {
      JsBarcode("#barcode").init();
    }
    loadEvent();
  }, []);

  const componentRef = useRef();

  return (
    <Content>
      <div className="d-none d-sm-block">
        <Row>
          <Col xs={12} sm={12} ref={componentRef}>
            <div className="document">
              {!canPayYet && (
                <Alert variant={`danger`}>
                  Este boleto já passou do prazo limite para pagamento.
                </Alert>
              )}
              <hr />
              <table>
                <tbody>
                  <tr className="topLine">
                    <td className="bankLogo">
                      <img src={logo} alt="" />
                    </td>
                    <td className="sideBorders center">
                      <span className="fsc24">237-2</span>
                    </td>
                    <td className="boletoNumber center">
                      <span>{data.linhaDigitavel}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table border="1">
                <tbody>
                  <tr>
                    <td width="70%" colSpan="6">
                      <span className="title">Local de Pagamento</span>
                      <br />
                      <span className="text">
                        Até o vencimento, preferencialmente no Banco Bradesco
                      </span>
                    </td>
                    <td width="30%">
                      <span className="title">Data de Vencimento</span>
                      <br />
                      <br />
                      <p className="content right text fwcb">
                        {data.dtVencimentoBoleto}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td width="70%" colSpan="6">
                      <span className="title">
                        Nome do Beneficiário / CNPJ / CPF / Endereço:
                      </span>
                      <br />
                      <table border="0" className="bnc">
                        <tbody>
                          <tr>
                            <td width="60%">
                              <span className="text">
                                {data.nomeBeneficiario}
                              </span>
                            </td>
                            <td>
                              <span className="text">
                                {/* CNPJ {data.nuCnpjBeneficiario} */}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <br />
                      <span className="text">
                        {`${data.logradouroBeneficiario} ${data.nuLogradouroBeneficiario} ${data.complementoLogradouroBeneficiario} - ${data.municipioBeneficiario} - ${data.ufBeneficiario} - ${data.cepBeneficiario}`}
                      </span>
                    </td>
                    <td width="30%">
                      {/* <span className="title">Agência/Código Beneficiário</span>
                <br />
                <br />
                <p className="content right">1234/12345-1</p> */}
                    </td>
                  </tr>

                  <tr>
                    <td width="15%" colSpan="2">
                      <span className="title">Data do Documento</span>
                      <br />
                      <p className="content center">{data.dtEmissao}</p>
                    </td>

                    <td width="10%">
                      <span className="title">Espécie doc</span>
                      <br />
                      <p className="content center">DS</p>
                    </td>
                    <td width="8%">
                      <span className="title">Aceite</span>
                      <br />
                      <p className="content center">N</p>
                    </td>
                    <td colSpan="2" width="17%">
                      <span className="title">Data Processamento</span>
                      <br />
                      <p className="content center">{data.dtEmissao}</p>
                    </td>
                    <td width="30%">
                      <span className="title">Número do titulo</span>
                      <br />
                      <p className="content center">{data.nuTituloGerado}</p>
                    </td>
                  </tr>

                  <tr>
                    <td width="15%">
                      <span className="title">Uso do Banco</span>
                      <br />
                      <p className="content center">&nbsp;</p>
                    </td>
                    <td width="10%"></td>
                    <td width="10%">
                      <span className="title">Espécie</span>
                      <br />
                      <p className="content center">R$</p>
                    </td>
                    <td width="8%" colSpan="2">
                      <span className="title">Quantidade</span>
                      <br />
                      <p className="content center">N</p>
                    </td>
                    <td>
                      <span className="title">Valor</span>
                      <br />
                      <p className="content center">{data.priceFormatted}</p>
                    </td>
                    <td width="30%">
                      <span className="title">(=) Valor do Documento</span>
                      <br />
                      <br />
                      <p className="content right">{data.priceFormatted}</p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="6" rowSpan="4">
                      <span className="title">
                        Instruções de responsabilidade do BENEFICIÁRIO. Qualquer
                        dúvida sobre este boleto contate o beneficiário.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="title">(-) Descontos/Abatimento</span>
                      <br />
                      <p className="content right">&nbsp;</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="title">(+) Juros/Multa</span>
                      <br />
                      <p className="content right">&nbsp;</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="title">(=) Valor Pago</span>
                      <br />
                      <p className="content right">&nbsp;</p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="7">
                      <table border="0" className="bnc">
                        <tbody>
                          <tr>
                            <td width="60%">
                              <span className="text">
                                <b>Nome do Pagador: </b> {data.nomePagador}
                              </span>
                            </td>
                            <td>
                              <span className="text">
                                <b>CNPJ/CPF: </b> {data.cpfcnpjPagador}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="text">
                                <b>Endereço: </b> {data.enderecoPagador} -{" "}
                                {data.bairroPagador} - {data.municipioPagador} -{" "}
                                {data.ufPagador} - {data.cepPagador}
                              </span>
                            </td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Row>
                <Col xs={12} sm={9}>
                  <svg
                    id="barcode"
                    jsbarcode-format="itf"
                    jsbarcode-value={data.cdBarras}
                    jsbarcode-textmargin="0"
                    jsbarcode-fontoptions="bold"
                    jsbarcode-displayvalue="false"
                    jsbarcode-background="#ffffff"
                  ></svg>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-end">
          <Col xs={12} sm={5} className="pt-4">
            <Button onClick={() => copy(data.linhaDigitavel)}>
              Copiar linha digitavel
            </Button>
            <ReactToPrint
              trigger={() => <Button variant="dark">Imprimir</Button>}
              content={() => componentRef.current}
            />
          </Col>
        </Row>
      </div>
      <div className="p-3 mb-2 bg-light text-dark d-block d-sm-none">
        <p className="mb-2">{data.linhaDigitavel}</p>
        <p>
          <Button onClick={() => copy(data.linhaDigitavel)}>
            Copiar linha digitavel
          </Button>
        </p>
      </div>
    </Content>
  );
}
