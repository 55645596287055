import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "~/services/api";
import { formatPrice } from "~/util/format";

import { Helmet } from "react-helmet";

import urlsystem from "~/config/URLSystem";
import URLApi from "~/config/URLApi";

import CartaoCC from "~/components/CartaoCC";
import BankSlip from "~/components/BankSlip";
import Spinner from "~/components/Spinner";
import student from "~/assets/student.jpg";
import logo from "~/assets/logoUnasp.png";

import { Form } from "@rocketseat/unform";
import {
  Row,
  Col,
  Button,
  Accordion,
  Card,
  Alert,
  Container,
  Image,
  InputGroup,
  FormControl,
} from "react-bootstrap";

import { toast } from "react-toastify";
import copy from "~/util/copy";
import youtube_parser from "~/util/youtube";
import replaceLink from "~/util/replaceLinks";

import imagePreLoading from "~/assets/imagePreLoading.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { FaDonate, FaFacebook, FaUserAlt } from "react-icons/fa";
import { IoLogoWhatsapp, IoIosArrowDropdown } from "react-icons/io";

import { Wrapper } from "./styles";

export default function StudentDonationPage() {
  const { slug } = useParams();

  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  const [dataPage, setDataPage] = useState(null);

  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [image, setimage] = useState("");
  const [url, seturl] = useState("");

  const [methodsPayment, setMethodsPayment] = useState([
    "cielo",
    "bradesco_bank_slip",
  ]);

  const [invoice, setInvoice] = useState("");

  useEffect(() => {
    async function loadData() {
      try {
        setMessage("Carregando...");
        const response = await api.get(`/donation/page/${slug}`);
        const data = response.data;
        if (data.status) {
          setDataPage({
            ...data.entity,
            textFormatted: replaceLink(data.entity.text),
            urlFormatted: `${urlsystem}/student/${slug}`,
            urlImage: !!data.entity.studentImageId
              ? `${URLApi}/image/${data.entity.studentImageId}`
              : null,
            videoEmbed: !!data.entity.videoUrl
              ? youtube_parser(data.entity.videoUrl)
              : null,
          });

          const ent = data.entity;

          settitle(`#EuApoio - ${ent.pageDescription}`);
          setdescription(ent.text || "");
          setimage(
            !!ent.studentImageId
              ? `${URLApi}/image/${ent.studentImageId}`
              : logo
          );
          seturl(`${urlsystem}/student/${slug}`);

          setLoading(false);
        } else {
          setMessage(data.message);
        }
      } catch (error) {
        setMessage("Erro ao carregar a página.. tente novamente");
      }
    }
    loadData();
  }, []);

  function mountAccordionPayment(data, index) {
    {
      switch (data) {
        case "cielo":
          return (
            <>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="info" eventKey={index}>
                  Cartão de Crédito <IoIosArrowDropdown />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={index}>
                <Card.Body className="payBox">
                  <CartaoCC
                    idclient={dataPage.pageId}
                    data={dataPage}
                    routePay="/donation/donate"
                    setInvoice={setInvoice}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </>
          );

        case "bradesco_bank_slip":
          return (
            <>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="danger" eventKey={index}>
                  Boleto Bancário <IoIosArrowDropdown />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={index}>
                <Card.Body className="payBox">
                  <BankSlip
                    idclient={dataPage.pageId}
                    data={dataPage}
                    routePay="/donation/donate"
                    setInvoice={setInvoice}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </>
          );

        default:
          break;
      }
    }
  }

  return (
    <>
      <Helmet>
        {/* General tags */}
        <title>{title}</title>
        <meta name="description" content={description} />
        {/* OpenGraph tags */}
        <meta name="og:url" content={url} />
        <meta name="og:title" content={title} />
        <meta name="og:description" content={description} />
        <meta name="og:image" content={image} />
        <meta name="og:type" content="website" />
      </Helmet>
      <Wrapper className="py-5">
        {loading ? (
          <Container>
            <h3>{message}</h3>
          </Container>
        ) : (
          <Container>
            <Row className="d-flex justify-content-center">
              <Col xs={12} sm={4} className="mb-4">
                <Card bg="light" className="bshadow">
                  <Card.Header>
                    <Col xs={12} sm={12} className="text-center py-1">
                      <div className="divImage">
                        {!!dataPage.studentImageId ? (
                          <>
                            <img src={imagePreLoading} id="preloadimage" />
                            <LazyLoadImage
                              effect="blur"
                              src={dataPage.urlImage}
                              visibleByDefault={
                                dataPage.src === imagePreLoading
                              }
                              className="imagePeople text-center"
                            />
                          </>
                        ) : (
                          <FaUserAlt size={200} color="#cccccc" />
                        )}
                      </div>
                    </Col>
                  </Card.Header>
                  <Card.Body>
                    <Card.Title>{dataPage.studentName}</Card.Title>
                    <hr />
                    <Card.Text>
                      <b>Curso:</b> {dataPage.studentCourse}
                      <br />
                      <b>Campus:</b> {dataPage.studentCampus}
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer className="text-muted">
                    <Row>
                      <Col>
                        <InputGroup className="mb-3">
                          <FormControl
                            placeholder={dataPage.urlFormatted}
                            type="text"
                            disabled
                          />
                          <InputGroup.Append>
                            <Button
                              variant="outline-info"
                              onClick={() => copy(dataPage.urlFormatted)}
                            >
                              Copiar Url
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row className="mb-3 justify-content-center">
                      <Col xs={6} md={10} lg={6}>
                        <b>Compartilhe também nas redes sociais</b>
                      </Col>
                      <Col
                        xs={4}
                        md={10}
                        lg={4}
                        className="d-flex justify-content-between"
                      >
                        <a
                          href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                            dataPage.urlFormatted
                          )}`}
                          target="_blank"
                        >
                          <IoLogoWhatsapp size={26} color="#34af23" />
                        </a>
                        <a
                          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                            dataPage.urlFormatted
                          )}&amp`}
                          target="_blank"
                        >
                          <FaFacebook size={26} color="#3b5998" />
                        </a>
                      </Col>
                    </Row>
                  </Card.Footer>
                </Card>
              </Col>
              <Col xs={12} sm={8} className="mb-4">
                {!!dataPage.videoEmbed && (
                  <div className="embed-responsive embed-responsive-16by9 mb-4">
                    <iframe
                      className="embed-responsive-item"
                      allowFullScreen
                      src={`https://www.youtube.com/embed/${dataPage.videoEmbed}`}
                    ></iframe>
                  </div>
                )}

                <Card bg="light" className="bshadow text-center mb-4">
                  <Card.Header>
                    <b>Carta do Aluno</b>
                  </Card.Header>
                  <Card.Body className="py-4">
                    <Card.Text
                      dangerouslySetInnerHTML={{
                        __html: dataPage.textFormatted,
                      }}
                    ></Card.Text>
                  </Card.Body>
                </Card>
                <Card
                  bg="dark"
                  text="white"
                  className="bshadow text-center mb-4"
                >
                  <Card.Header>
                    <b>Sobre {dataPage.campaignName}</b>​
                  </Card.Header>
                  <Card.Body className="py-4">
                    <Card.Text>{dataPage.campaignDescription}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} sm={12}>
                <Card
                  bg={"info"}
                  text={"white"}
                  className="bshadow mb-4 text-center"
                >
                  <Card.Body>
                    <Card.Title>
                      Suas doações podem chegar até onde suas mãos não podem.
                      Seja solidário e contribua!!
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} sm={12}>
                <Card bg="light" className="bshadow text-center">
                  <Card.Header>
                    <h4>
                      {dataPage.pageDescription}{" "}
                      <FaDonate size={18} color="#28a745" />
                    </h4>
                  </Card.Header>
                  <Card.Body className="py-4">
                    <h5>Escolha o método para doação</h5>
                    <Row>
                      <Col>
                        <Accordion>
                          {methodsPayment.map((method, index) => (
                            <Card key={index}>
                              {mountAccordionPayment(method, index)}
                            </Card>
                          ))}
                        </Accordion>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </Wrapper>
    </>
  );
}
