import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { toast } from "react-toastify";

import history from "~/services/history";

import api from "~/services/api";
import { Button, Card, Col, Container, Row } from "react-bootstrap";

// import { Container } from './styles';

function ConfirmPass() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  let location = useLocation();

  async function ConfirmPasswordRecover(url) {
    if (!!newPassword || !!confirmNewPassword) {
      setLoading(true);
      try {
        const obj = {
          NewPassword: newPassword,
          ConfirmPassword: confirmNewPassword,
        };
        const response = await api.post(
          `/authentication/confirmpasswordrecover${url}`,
          obj
        );
        const data = response.data;
        const { message, status } = data;
        if (response.status === 200 && status) {
          setConfirmPassword(true);
          toast.success("Senha Alterada com sucesso!");
          history.push("/");
        } else {
          toast.error(`Erro na solicitação.. ${message}`);
        }
      } catch (error) {
        if (!!error.response) {
          toast.error(Object.values(error.response.data.errors)[0][0]);
        }
      }
      setLoading(false);
    } else {
      toast.warn("Preencha todos os campos!");
    }
  }

  return (
    <Container>
      <Row>
        <Col className="justify-content-center" style={{ display: "flex" }}>
          <Card className="m-4">
            <Card.Header>
              <Card.Title> Alterar Senha</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col className="col-12 px-4">
                  <input
                    type={"password"}
                    style={{ borderRadius: "0px" }}
                    className="form-control "
                    placeholder="Nova Senha"
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </Col>

                <Col className="col-12 px-4 py-3">
                  <input
                    type={"password"}
                    style={{ borderRadius: "0px" }}
                    className="form-control "
                    placeholder="Confirmar Nova Senha"
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                  />

                  <Row className="mt-4 px-2">
                    <Button
                      className="w-100"
                      variant="success"
                      onClick={() => ConfirmPasswordRecover(location.search)}
                    >
                      Salvar
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ConfirmPass;
