export default function replaceLink(text) {
  text = text.replace(/(?:\r\n|\r|\n)/g, "<br>");
  return (text || "").replace(
    /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
    function (match, space, url) {
      var hyperlink = url;
      if (!hyperlink.match("^https?://")) {
        hyperlink = "http://" + hyperlink;
      }
      return (
        space + '<a href="' + hyperlink + '" target="_blank">' + url + "</a>"
      );
    }
  );
}
