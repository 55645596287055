import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import api from "~/services/api";
import buildURLQuery from "~/util/buildURLQuery";

import { format } from "date-fns";
import pt from "date-fns/locale/pt";

import { Form, Input, Select } from "@rocketseat/unform";
import {
  Table,
  Container,
  Row,
  Col,
  Dropdown,
  Spinner,
  Button,
  Modal,
  Card,
  InputGroup,
  FormControl,
  ListGroup,
} from "react-bootstrap";

import { toast } from "react-toastify";

import { IoMdMenu, IoMdSearch } from "react-icons/io";
import { AiFillMoneyCollect } from "react-icons/ai";
import { MdClear, MdUpdate, MdAddCircleOutline } from "react-icons/md";
// import { Container } from './styles';

export default function Campanhas() {
  const tenantProfile = useSelector((state) => state.header.tenantProfile);

  const formRef = useRef(null);
  const searchRef = useRef(null);
  const [searching, setSearching] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [timeout, setTimeOut] = useState(0);

  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);

  const [activePage, setActivePage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [totalItens, setTotalItens] = useState(0);

  const [updatePending, setUpdatePending] = useState({});
  const [modalUpdate, setModalUpdate] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  // change new tenant
  useEffect(() => {
    setActivePage(1);
  }, [tenantProfile, searchString]);

  /* Load data pages */
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);

        const response = await api.get(
          `/AdminArea/GetTenantCampaigns/${tenantProfile}/${activePage}/${itemPerPage}?${buildURLQuery(
            { search: searchString }
          )}`
        );

        const dataDonations = response.data;
        setTotalItens(dataDonations.indexSize);
        setPages(
          dataDonations.entities.map((x) => ({
            ...x,
            dateFormatted: x.startDate
              ? format(new Date(x.startDate), "dd/MMM/yyyy HH:mm", {
                  locale: pt,
                })
              : null,
          }))
        );
      } catch (error) {
        if (!!error.response && error.response.status !== 401) {
          toast.error("Erro ao carregar a página");
        }
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [activePage, setItemPerPage, tenantProfile, searchString]);

  useEffect(() => {
    if (searching) {
      searchRef.current.focus();
    }
  }, [searching]);

  const handleViewRequest = (data) => {
    setUpdatePending({});
    setUpdatePending(data);
    setModalUpdate(true);
  };

  const handleFind = (e) => {
    const vl = e.target.value;

    if (timeout) clearTimeout(timeout);
    setTimeOut(
      setTimeout(() => {
        setSearchString(vl);
      }, 400)
    );
  };

  const handleClear = () => {
    setSearchString("");
    setSearching(false);
  };

  const handleSearch = () => {
    setSearching(true);
  };

  const searchFunction = (data) => {};

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Campanhas</h1>
      </div>

      {/* Search */}
      <Row className="d-flex justify-content-center">
        <Col xs={11} sm={12} className="d-flex justify-content-end mb-2">
          {!!searching ? (
            <Form ref={formRef} onSubmit={searchFunction}>
              <InputGroup className="">
                <FormControl
                  type="text"
                  placeholder="Procurar aluno"
                  className="form-control inputSearch"
                  //value={searchString}
                  onChange={handleFind}
                  ref={searchRef}
                />
                <InputGroup.Append>
                  <Button variant="outline-danger" onClick={handleClear}>
                    <MdClear />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          ) : (
            <Button
              variant="outline-info"
              onClick={handleSearch}
              title="Clique para procurar"
            >
              <IoMdSearch />
            </Button>
          )}

          {/* Nova Campanha */}
          <Button
            variant="outline-success"
            onClick={() => toast.info("funcionalidade não implementada")}
            title="Clique para adicionar nova campanha"
            className="ml-2"
          >
            <MdAddCircleOutline />
          </Button>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center">
        <Col xs={11} sm={12}>
          <div className="table-responsive pt-3">
            <Table responsive striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>Campanha</th>
                  <th>Descrição</th>
                  <th>Data de início</th>
                  <th>Status</th>
                  <th>Link</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={6}>
                      <div className="contentcenter">
                        <Spinner animation="border" /> Carregando
                      </div>
                    </td>
                  </tr>
                ) : pages.length === 0 ? (
                  <tr>
                    <td colSpan={6} className="text-center">
                      Nenhum registro
                    </td>
                  </tr>
                ) : (
                  pages.map((item, index) => (
                    <tr key={item.campaignId}>
                      <td>{item.name}</td>
                      <td>{item.pageDefaultDescription}</td>
                      <td>{item.dateFormatted}</td>
                      <td>{item.active ? "Ativo" : "Inativo"}</td>
                      <td>
                        <Link
                          to={`/admin/campaign/transactions-done/${item.campaignId}`}
                          className="btn btn-light"
                        >
                          <AiFillMoneyCollect
                            size={16}
                            color="#149098"
                            className="mr-1"
                          />
                          Tranferências Realizadas
                        </Link>
                      </td>
                      <td className="tdControl">
                        <Dropdown drop="left">
                          <Dropdown.Toggle
                            variant="secondary"
                            id="dropdown-basic"
                          >
                            <IoMdMenu size={22} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => handleViewRequest(item)}
                            >
                              <MdUpdate
                                size={16}
                                color="#3b5998"
                                className="mr-1"
                              />
                              Atualizar
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>

      {/* Modal Atualizar Página */}
      <Modal
        className="mt-5"
        size="lg"
        show={modalUpdate}
        onHide={() => setModalUpdate(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Campanha</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col>
              <Card bg="light" className="text-center mb-4 bshadow">
                <Card.Header>
                  <b>Avaliar página</b>
                </Card.Header>
                <Card.Body className="py-4">
                  <Row>
                    <Col xs={12} sm={12}>
                      <ListGroup className="text-left">
                        {Object.entries(updatePending)
                          .filter((x) => x[1] !== null)
                          .map((x, index) => (
                            <ListGroup.Item
                              key={index}
                              dangerouslySetInnerHTML={{
                                __html: `<b>${x[0]}</b> - ${x[1]}`,
                              }}
                            ></ListGroup.Item>
                          ))}
                      </ListGroup>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
