import axios from "axios";
//import singleToast from "~/util/singleToast";
import { toast } from "react-toastify";
import history from "~/services/history"; /* 
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "~/store/modules/auth/actions"; */
const domain = window.location.hostname;

const api = axios.create({
  //baseURL: "http://localhost:3333"
  baseURL:
    process.env.NODE_ENV === "development"
      ? "https://localhost:5001"
      : domain === "euapoio.homolog.s3-website-sa-east-1.amazonaws.com"
        ? "https://homologeupoio.unasp.edu.br/"
        : "https://api.euapoio.unasp.edu.br",
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // This function is part of a promise chain.
    // It needs to return a value or another promise so the caller knows when it
    // has completed.
    // Pass all non 401s back to the caller.
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }

    //const dispatch = useDispatch();

    /* if (timeout) clearTimeout(timeout);
    setTimeOut(
      setTimeout(() => {
        setSearchString(vl);
      }, 400)
    ); */

    //singleToast("Sua sessão expirou. Faça o login novamente", "error");
    toast.error("Sua sessão expirou. Faça o login novamente");
    history.push("/logout");
    /*setTimeout(function () {
      dispatch(signOut());
      history.push("/login");
    }, 2000); */

    /*
    const tokens = {
      token: localStorage.getItem("token"),
      refreshToken: localStorage.getItem("refreshToken")
    };

    
    return axios
      .post(`api/token/refresh`, tokens)
      .then(response => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("refreshToken", response.data.refreshToken);

        
        axios.defaults.headers.common["Authorization"] = `Bearer ${
          response.data.token
        }`;

        
        error.hasRefreshedToken = true;
        return Promise.reject(tokenError);
      })
      .catch(() => {
        const tokenError = new Error("Cannot refresh token");
        tokenError.originalError = error;
        return Promise.reject(tokenError);
      });*/
  }
);

export default api;
