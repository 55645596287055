import styled from "styled-components";

export const Wrapper = styled.div`
  align-self: center;
  margin-bottom: 0px;
  text-align: center;

  label {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    /* img {
      height: 160px;
      width: 160px;
      border-radius: 50%;
      border: 3px solid rgba(255, 255, 255, 0.3);
      background: #eee;
    } */

    input {
      display: none;
    }
  }
`;
