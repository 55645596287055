import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "~/services/api";
import urlsystem from "~/config/URLSystem";
import buildURLQuery from "~/util/buildURLQuery";

import { CSVLink, CSVDownload, } from "react-csv";

import { formatPrice } from "~/util/format";

import enumState from "~/util/enumState";
import Switch from "react-switch";
import youtube_parser from "~/util/youtube";
import enumMetodPayment from "~/util/enumMetodPayment";

import { format } from "date-fns";
import pt from "date-fns/locale/pt";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { Form, Input, Select } from "@rocketseat/unform";
import {
  Table,
  Container,
  Row,
  Col,
  Dropdown,
  Spinner,
  Button,
  Modal,
  Card,
  InputGroup,
  FormControl,
  Jumbotron,
} from "react-bootstrap";
import { toast } from "react-toastify";

import PaginationComponent from "react-js-pagination";
import BoletoComponent from "~/components/BankSlip/BoletoComponent";
import parseDataBradesco from "~/util/parseDataBradesco";

// import { Container } from './styles';
import { IoMdMenu, IoMdSearch } from "react-icons/io";
import { MdUpdate, MdClear } from "react-icons/md";
import { FaReceipt, FaFileInvoice } from "react-icons/fa";
import enumTransactionStatus from "~/util/enumTransactionStatus";

export default function Doacoes() {
  const tenantProfile = useSelector((state) => state.header.tenantProfile);

  const searchRef = useRef(null);
  const [searching, setSearching] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [timeout, setTimeOut] = useState(0);

  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);

  const [activePage, setActivePage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [totalItens, setTotalItens] = useState(0);

  const [donations, setDonations] = useState([]);
  const [infoDonations, setInfoDonations] = useState({});
  const [modal, setModal] = useState(false);

  const [slipData, setSlipData] = useState(null);
  const [modalSlip, setModalSlip] = useState(false);

  const [modalCSV, setModalCSV] = useState(false);

  const data = new Date();

  const csvData = [
    ["firstname", "lastname", "email"],
    ["Ahmed", "Tomi", "ah@smthing.co.com"],
    ["Raed", "Labes", "rl@smthing.co.com"],
    ["Yezzi", "Min l3b", "ymin@cocococo.com"]
  ];

  // change new tenant
  useEffect(() => {
    setActivePage(1);
  }, [tenantProfile, searchString]);

  /* Load data pages */
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);

        const response = await api.get(
          `/AdminArea/GetDonations/${tenantProfile}/${activePage}/${itemPerPage}?${buildURLQuery(
            { search: searchString }
          )}`
        );

        const dataDonations = response.data;
        setTotalItens(dataDonations.indexSize);

        setPages(
          dataDonations.entities.map((x) => ({
            ...x,
            urlFormatted: `${urlsystem}/student/${x.url}`,
            dateFormatted: x.date
              ? format(new Date(x.date), "dd/MM/yyyy HH:mm", {
                locale: pt,
              })
              : null,
            valueFormatted: formatPrice(x.value || 0),
            paymentMethodCodeFormatted: enumMetodPayment.find(
              (y) => y.id === x.paymentMethodCode
            )
              ? enumMetodPayment.find((y) => y.id === x.paymentMethodCode).title
              : "Método inválido",
          }))
        );
      } catch (error) {
        if (!!error.response && error.response.status !== 401) {
          toast.error("Erro ao carregar a página");
        }
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [activePage, setItemPerPage, tenantProfile, searchString]);

  useEffect(() => {
    if (searching) {
      searchRef.current.focus();
    }
  }, [searching]);

  const handleFind = (e) => {
    const vl = e.target.value;

    if (timeout) clearTimeout(timeout);
    setTimeOut(
      setTimeout(() => {
        setSearchString(vl);
      }, 400)
    );
  };

  const handleClear = () => {
    setSearchString("");
    setSearching(false);
  };

  const handleSearch = () => {
    setSearching(true);
  };

  const handleModalReceipt = async (data) => {
    setInfoDonations(data);
    setModal(true);
  };

  const handleModalBankSlip = async (data) => {
    setModalSlip(true);
    setSlipData(parseDataBradesco(data));
  };

  const handleModalCSV = async () => {
    setModalCSV(true);
  };

  const renderMenu = (item) => {
    if (item.processed) {
      return (
        <Dropdown.Item
          onClick={() => {
            handleModalReceipt(item);
          }}
        >
          <FaReceipt size={18} color="#3b5998" /> Comprovante
        </Dropdown.Item>
      );
    }

    if (
      item.paymentMethodCode === 20 &&
      item.paymentStatus === 10 &&
      item.processed === false
    ) {
      return (
        <Dropdown.Item
          onClick={() => {
            handleModalBankSlip(item.paymentData);
          }}
        >
          <FaFileInvoice size={18} color="#3b5998" /> 2ª via do boleto
        </Dropdown.Item>
      );
    }
  };

  const searchFunction = (data) => { };
  const componentRef = useRef();
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Todas doações</h1>
      </div>
      {/* Search */}
      <Row className="d-flex justify-content-center">
        <Col xs={11} sm={12} className="d-flex justify-content-end mb-2">
          <Button variant="outline-primary mr-3" onClick={handleModalCSV}>
            CSV
          </Button>
          {!!searching ? (
            <Form onSubmit={searchFunction}>
              <InputGroup className="">
                <FormControl
                  type="text"
                  placeholder="Procurar aluno"
                  className="form-control inputSearch"
                  onChange={handleFind}
                  ref={searchRef}
                />
                <InputGroup.Append>
                  <Button variant="outline-danger" onClick={handleClear}>
                    <MdClear />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          ) : (
              <Button
                variant="outline-info"
                onClick={handleSearch}
                title="Clique para procurar"
              >
                <IoMdSearch />
              </Button>
            )}
        </Col>
      </Row>

      {/* List */}
      <Row className="d-flex justify-content-center">
        <Col xs={11} sm={12}>
          <div className="table-responsive pt-3">
            <Table responsive striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>Id Doação</th>
                  <th>Aluno</th>
                  <th>Doador</th>
                  <th>Data</th>
                  <th>Método</th>
                  <th>Status</th>
                  <th>Valor</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={8}>
                      <div className="contentcenter">
                        <Spinner animation="border" /> Carregando
                      </div>
                    </td>
                  </tr>
                ) : pages.length === 0 ? (
                  <tr>
                    <td colSpan={8} className="text-center">
                      Nenhum registro
                    </td>
                  </tr>
                ) : (
                      pages.map((item, index) => (
                        <tr key={item.donationId}>
                          <td>{item.donationId}</td>
                          <td>{item.studentName}</td>
                          <td>{item.giverName}</td>
                          <td>{item.dateFormatted}</td>
                          <td>{item.paymentMethodCodeFormatted}</td>
                          <td>{!!item.processed ? "Processado" : "Aguardando"}</td>
                          <td>{item.valueFormatted}</td>
                          <td className="tdControl">
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                              >
                                <IoMdMenu size={22} />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>{renderMenu(item)}</Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))
                    )}
              </tbody>
            </Table>
            <Container hidden={loading || pages.length == 0 ? true : false}>
              <Row
                className={`justify-content-center ${
                  totalItens <= itemPerPage ? "d-none" : "d-flex"
                  }`}
              >
                <Col xs={10} sm={5} className="d-flex justify-content-center">
                  <PaginationComponent
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination mb-0"
                    activePage={activePage}
                    itemsCountPerPage={itemPerPage}
                    totalItemsCount={totalItens}
                    pageRangeDisplayed={5}
                    onChange={(e) => setActivePage(e)}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>

      {/* Modal Consulta */}
      <Modal
        className="mt-5"
        size="lg"
        show={modal}
        onHide={() => setModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Detalhes da doação
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            {/* Listagem das doações */}

            <Col xs={12}>
              {!!infoDonations.studentName && (
                <>
                  <Jumbotron ref={componentRef}>
                    <h4>
                      {!!infoDonations.studentName &&
                        `Doação feita para ${infoDonations.studentName}`}
                    </h4>
                    <p>
                      <b>Curso:</b> {infoDonations.studentCourse || ""}
                    </p>
                    <p>
                      <b>Link da doação:</b> {infoDonations.urlFormatted}
                    </p>
                    <p>
                      <b>Código da doação:</b> {infoDonations.donationId}
                    </p>
                    <p>
                      <b>Valor:</b> {infoDonations.valueFormatted}
                    </p>
                    <p>
                      <b>Data da doação:</b> {infoDonations.dateFormatted}
                    </p>
                    <p>
                      <b>Nome da campanha:</b> {infoDonations.campaignName}
                    </p>
                    <p>
                      <b>Método de pagamento: </b>
                      {infoDonations.paymentMethodCodeFormatted}
                    </p>
                    {!!infoDonations.isRecurrence ? (
                      <p>
                        <b>Doação recorrente</b>
                      </p>
                    ) : (
                        <p>
                          <b>Doação única</b>
                        </p>
                      )}
                    <p>
                      <b>Status do pagamento: </b>
                      {!!infoDonations.processed ? "Processado" : "Em análise"}
                    </p>
                  </Jumbotron>
                </>
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Modal Boleto */}
      <Modal
        className="mt-5"
        size="xl"
        show={modalSlip}
        onHide={() => setModalSlip(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Segunda via do boleto
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col xs={12}>
              {slipData != null ? (
                <BoletoComponent data={slipData} />
              ) : (
                  <div className="contentcenter">
                    <Spinner animation="border" /> Carregando
                  </div>
                )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Modal CSV */}
      <Modal
        className="mt-5"
        size="lg"
        show={modalCSV}
        onHide={() => setModalCSV(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Arquivo CSV
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col xs={12}>
              {/* <CSVLink className="btn btn-outline-primary mr-3"
                data={
                  "101330;Instituto Adventista de Ensino - Reitoria;;;;;;\n" +
                  dataLote.map((it, i) => (
                    `2139090;${it.originStudentRa};10;1010201;0E;${it.transactionValue * 100};N;RA. ${it.originStudentRa} ${it.originStudentName}\n`
                  )).join("") +
                  `1136001;${entidade};10;1010201;0E;-${
                  dataLotEntity.reduce(function (acumulador, valorAtual) { return acumulador + valorAtual.transactionValue; }, valorInicial) * 100
                  };N;Liq. Conbrança ${dataLote}`
                }
                separator={";"}
                enclosingCharacter={` `}
                filename={data.getDate() + "-" + (data.getMonth() + 1) + "-" + data.getFullYear() + ".csv"}
              >Gerar CSV {console.log(pages)}</CSVLink> */}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
