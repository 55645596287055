import styled from "styled-components";

export const Wrapper = styled.div`
  .donations .card {
    width: 100%;
  }

  .borderRadiusLeft {
    border-radius: 30px 0px 0 30px;
    border-right: none;
    padding-left: 1.15rem;
    &:focus {
      border: #ced4da 1px solid;
      border-right: none;
    }
  }

  .borderAndBackground {
    border: 1px solid #ced4da;

    background: #fff;
  }
  .borderRadiusRight {
    border-radius: 0px 30px 30px 0 !important;
    cursor: auto;
    border-left: none;

    &:active {
      box-shadow: none;
    }
    &:focus {
      outline: 0;
    }
  }
`;
