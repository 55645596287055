import styled from "styled-components";

export const Content = styled.div`
  #banco_logo {
    max-width: 40px;
    max-height: 40px;
  }
  #barcode {
    width: 100%;
  }

  body {
    font-family: "Arial";
  }

  @media print {
    .no-print,
    .no-print * {
      display: none !important;
    }
  }

  .document {
    margin: auto auto;
    width: 100%;
    background-color: #fff;
    padding: 10px 20px;
    text-align: left;
  }

  .headerBtn {
    margin: auto auto;
    width: 216mm;
    background-color: #fff;
    display: none;
  }

  table {
    width: 100%;
    position: relative;
    border-collapse: collapse;
  }

  .bankLogo {
    width: 28%;
  }

  .boletoNumber {
    width: 62%;
    font-weight: bold;
  }

  .center {
    text-align: center;
  }

  .right {
    text-align: right;
    right: 20px;
  }

  td {
    position: relative;
  }

  .title {
    position: absolute;
    left: 0px;
    top: 0px;
    font-size: 12px;
    font-weight: bold;
  }

  .text {
    font-size: 12px;
  }

  p.content {
    padding: 0px;
    width: 100%;
    margin: 0px;
    font-size: 12px;
    text-align: left;
  }

  .sideBorders {
    border-left: 1px solid black;
    border-right: 1px solid black;
  }

  hr {
    size: 1;
    border: 1px dashed;
  }

  br {
    content: " ";
    display: block;
    margin: 12px 0;
    line-height: 12px;
  }

  .print {
    /* TODO(dbeam): reconcile this with overlay.css' .default-button. */
    background-color: rgb(77, 144, 254);
    background-image: linear-gradient(
      to bottom,
      rgb(77, 144, 254),
      rgb(71, 135, 237)
    );
    border: 1px solid rgb(48, 121, 237);
    color: #fff;
    text-shadow: 0 1px rgba(0, 0, 0, 0.1);
  }

  .btnDefault {
    font-kerning: none;
    font-weight: bold;
  }

  .btnDefault:not(:focus):not(:disabled) {
    border-color: #808080;
  }

  button {
    border: 1px;
    padding: 5px;
    line-height: 20px;
  }

  .fsc24 {
    font-size: 24px;
    font-weight: bold;
  }

  .fwcb {
    font-weight: bold;
  }

  .bnc {
    border: none;
  }

  #tarc {
    text-align: right;
  }
`;
