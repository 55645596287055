import React, { useRef, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import api from "~/services/api";
import urlsystem from "~/config/URLSystem";
import buildURLQuery from "~/util/buildURLQuery";
import { formatPrice } from "~/util/format";

import CurrencyInput from "~/util/CurrencyInput";

import history from "~/services/history";

import Switch from "react-switch";

import { format } from "date-fns";
import pt from "date-fns/locale/pt";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import "./index.css";

import { Form, Input, Select } from "@rocketseat/unform";
import {
  Table,
  Container,
  Row,
  Col,
  Dropdown,
  Spinner,
  Button,
  Modal,
  Card,
  InputGroup,
  FormControl,
  Jumbotron,
} from "react-bootstrap";
import { toast } from "react-toastify";

import PaginationComponent from "react-js-pagination";

// import { Container } from './styles';
import { IoMdMenu, IoMdSearch } from "react-icons/io";
import { MdMore, MdClear } from "react-icons/md";
import { FaTimes, FaCheck, FaFilter } from "react-icons/fa";

import enumTransactionType from "~/util/enumTransactionType";
import enumTransactionStatus from "~/util/enumTransactionStatus";

export default function TransferenciaPage() {
  let location = useLocation();
  const tenantProfile = useSelector((state) => state.header.tenantProfile);

  const underAnalysis = useMemo(() => {
    const search = new URLSearchParams(location.search);
    const view = search.get("mode");
    return view === "underAnalysis" ? true : false;
  });

  const formRef = useRef(null);
  const searchRef = useRef(null);
  const [searching, setSearching] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [timeout, setTimeOut] = useState(0);

  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);

  const [activePage, setActivePage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [totalItens, setTotalItens] = useState(0);

  const [updatePending, setUpdatePending] = useState({});
  const [modalUpdate, setModalUpdate] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [statusAprove, setStatusAprove] = useState(0);
  const [valueAprove, setValueAprove] = useState(0);

  // change new tenant
  useEffect(() => {
    setActivePage(1);
  }, [tenantProfile, searchString]);

  /* Load data pages */
  useEffect(() => {
    loadData();
  }, [activePage, itemPerPage, tenantProfile, searchString, underAnalysis]);

  useEffect(() => {
    if (searching) {
      searchRef.current.focus();
    }
  }, [searching]);

  const loadData = async () => {
    try {
      setLoading(true);

      const urlBase = underAnalysis
        ? "getinanalysistransactionrequests"
        : "GetAllTransactionRequests";

      const response = await api.get(
        `/adminarea/${urlBase}/${tenantProfile}/${activePage}/${itemPerPage}?${buildURLQuery(
          { search: searchString }
        )}`
      );

      const dataDonations = response.data;
      setTotalItens(dataDonations.indexSize);
      setPages(
        dataDonations.entities.map((x) => ({
          ...x,
          urlFormatted: `${urlsystem}/student/${x.url}`,
          dateFormatted: x.requestDate
            ? format(new Date(x.requestDate), "dd/MMM/yyyy HH:mm", {
              locale: pt,
            })
            : null,
          statusFormatted: enumTransactionStatus.find((y) => y.id === x.status)
            ? enumTransactionStatus.find((y) => y.id === x.status).label
            : "Método inválido",
          transactionTypeFormatted: enumTransactionType.find(
            (y) => y.id === x.transactionType
          )
            ? enumTransactionType.find((y) => y.id === x.transactionType).label
            : "Tipo inválido",
          approvedValueFormatted: formatPrice(x.approvedValue || 0),
          requestValueFormatted: formatPrice(x.requestValue || 0),
        }))
      );
    } catch (error) {
      if (!!error.response && error.response.status !== 401) {
        toast.error("Erro ao carregar a página");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleViewRequest = async (data) => {
    setUpdatePending(data);

    setValueAprove(data.requestValue);

    setModalUpdate(true);
  };

  const handleAproveRequest = async (data) => {
    try {
      const valReplaced = isNaN(valueAprove)
        ? Number(valueAprove.replace("R$ ", "").replace(/,/g, ""))
        : valueAprove;

      const json = {
        transactionRequestId: data.transactionRequestId,
        status: statusAprove,
        approvedValue: valReplaced,
      };

      const response = await api.post(
        `/AdminArea/moderatetransactionrequest/${data.dataKey}`,
        json
      );

      const dt = response.data;
      const { message, status, entity } = dt;
      if (response.status == 200 && status) {
        loadData();
        setModalUpdate(false);
        toast.success("Sucesso!");
      } else {
        toast.error(`Erro na solicitação.. ${message}`);
      }
    } catch (error) {
      if (!!error.response && error.response.status !== 401) {
        toast.error("Erro na solicitação.. tente mais tarde");
      }
    }
  };

  const handleFind = (e) => {
    const vl = e.target.value;

    if (timeout) clearTimeout(timeout);
    setTimeOut(
      setTimeout(() => {
        setSearchString(vl);
      }, 400)
    );
  };

  const handleClear = () => {
    setSearchString("");
    setSearching(false);
  };

  const handleSearch = () => {
    setSearching(true);
  };

  const handleRenderizeTransfer = (status) => {
    if (status) {
      history.push("/admin/transactions?mode=underAnalysis");
    } else {
      history.push("/admin/transactions");
    }
  };

  const searchFunction = (data) => { };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Pedidos de Resgate</h1>
      </div>

      {/* Search */}
      <Row className="d-flex justify-content-center">
        <Col xs={11} sm={6} className="d-flex justify-content-start mb-2">
          <label className="switchBox mb-0">
            <span>Exibir apenas status em análise</span>
            <Switch onChange={handleRenderizeTransfer} checked={underAnalysis} />
          </label>
        </Col>
        <Col xs={11} sm={6} className="d-flex justify-content-end mb-2">
          <Form
            initialData={{ itensPerPage: itemPerPage }}
            onSubmit={searchFunction}
            className="mr-2"
          >
            <InputGroup className="">
              <Select
                name="itensPerPage"
                placeholder="Items por página"
                className="form-control"
                options={[
                  {
                    value: 10,
                    id: 10,
                    title: 10,
                  },
                  {
                    value: 25,
                    id: 25,
                    title: 25,
                  },
                  {
                    value: 50,
                    id: 50,
                    title: 50,
                  },
                  {
                    value: 100,
                    id: 100,
                    title: 100,
                  },
                ]}
                onChange={({ target }) => setItemPerPage(target.value)}
              />
            </InputGroup>
          </Form>

          {!!searching ? (
            <Form onSubmit={searchFunction}>
              <InputGroup className="">
                <FormControl
                  type="text"
                  placeholder="Procurar aluno"
                  className="form-control inputSearch"
                  onChange={handleFind}
                  ref={searchRef}
                />
                <InputGroup.Append>
                  <Button variant="outline-danger" onClick={handleClear}>
                    <MdClear />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          ) : (
              <Button
                variant="outline-info"
                onClick={handleSearch}
                title="Clique para procurar"
              >
                <IoMdSearch />
              </Button>
            )}
        </Col>
      </Row>

      {/* List */}
      <Row className="d-flex justify-content-center">
        <Col xs={11} sm={12}>
          <div className="table-responsive pt-3">
            <Table responsive striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Aluno</th>
                  <th>Campanha</th>
                  <th>Finalidade</th>
                  <th>Valor Solicitado</th>
                  <th>Valor Aprovado</th>
                  <th>Data</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={8}>
                      <div className="contentcenter">
                        <Spinner animation="border" /> Carregando
                      </div>
                    </td>
                  </tr>
                ) : pages.length === 0 ? (
                  <tr>
                    <td colSpan={8} className="text-center">
                      Nenhum registro
                    </td>
                  </tr>
                ) : (
                      pages.map((item, index) => (
                        <tr key={item.transactionRequestId}>
                          {
                            item.status === 20 ?
                              <td className="Aprovado">{item.statusFormatted}</td> :
                              item.status === 25 ?
                                <td className="Parcialmente">{item.statusFormatted}</td> :
                                item.status === 30 ?
                                  <td className="Negado">{item.statusFormatted}</td> :
                                  <td className="Analise">{item.statusFormatted}</td>
                          }
                          <td>{item.studentName}</td>
                          <td>{item.originPageCampaignName}</td>
                          <td>{item.transactionTypeFormatted}</td>
                          <td>{item.requestValueFormatted || ""}</td>
                          <td>{item.approvedValueFormatted || ""}</td>
                          <td>{item.dateFormatted}</td>
                          <td className="tdControl">
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                              >
                                <IoMdMenu size={22} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => handleViewRequest(item)}
                                >
                                  <MdMore size={18} color="#17a2b8" /> Visualizar
                            </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))
                    )}
              </tbody>
            </Table>
            <Container hidden={loading || pages.length === 0 ? true : false}>
              <Row
                className={`justify-content-center ${
                  totalItens <= itemPerPage ? "d-none" : "d-flex"
                  }`}
              >
                <Col xs={10} sm={5} className="d-flex justify-content-center">
                  <PaginationComponent
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination mb-0"
                    activePage={activePage}
                    itemsCountPerPage={itemPerPage}
                    totalItemsCount={totalItens}
                    pageRangeDisplayed={5}
                    onChange={(e) => setActivePage(e)}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>

      {/* Modal Moderar Transferencia */}
      <Modal
        className="mt-5"
        size="md"
        show={modalUpdate}
        onHide={() => setModalUpdate(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Transferência financeira
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col>
              <>
                <Jumbotron className="py-4">
                  <h5>
                    <b>Aluno: </b>
                    {updatePending.studentName || ""}
                  </h5>
                  <p>
                    <b>Campanha: </b>
                    {updatePending.originPageCampaignName || ""}
                  </p>
                  <p>
                    <b>Data de Solicitação: </b>
                    {updatePending.dateFormatted || ""}
                  </p>
                  <p>
                    <b>Status: </b>
                    {updatePending.statusFormatted || ""}
                  </p>
                  <p>
                    <b>Finalidade: </b>
                    {updatePending.transactionTypeFormatted || ""}
                  </p>
                  <p>
                    <b>Valor Solicitado: </b>
                    {updatePending.requestValueFormatted || ""}
                  </p>
                  {(updatePending.status === 20 ||
                    updatePending.status === 25) && (
                      <p>
                        <b>Valor Aprovado: </b>
                        {updatePending.approvedValueFormatted || ""}
                      </p>
                    )}
                </Jumbotron>
                {updatePending.status === 10 && (
                  <Form
                    initialData={updatePending}
                    onSubmit={handleAproveRequest}
                  >
                    <Input name="transactionRequestId" type="hidden" />
                    <Input name="dataKey" type="hidden" />
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon3">
                          Valor
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <CurrencyInput
                        placeholder="R$ 0.00"
                        className="form-control"
                        value={valueAprove}
                        onChange={({ target }) => setValueAprove(target.value)}
                        name="requestValue"
                        type="text"
                      />
                    </InputGroup>

                    <Row>
                      <Col>
                        <Button
                          variant="danger"
                          type="submit"
                          block
                          disabled={modalLoading}
                          onClick={() => setStatusAprove(30)}
                        >
                          {modalLoading ? (
                            <Spinner animation="border" />
                          ) : (
                              <>
                                {`Recusar `}
                                <FaTimes />
                              </>
                            )}
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          variant="info"
                          type="submit"
                          block
                          disabled={modalLoading}
                          onClick={() => setStatusAprove(20)}
                        >
                          {modalLoading ? (
                            <Spinner animation="border" />
                          ) : (
                              <>
                                {`Aprovar `}
                                <FaCheck />
                              </>
                            )}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
