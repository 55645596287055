import React from "react";
import {
  MdEventNote,
  MdSettings,
  MdHome,
  MdAccountBalanceWallet,
} from "react-icons/md";
import { IoIosSend, IoIosCreate } from "react-icons/io";
import { FaDonate, FaUserPlus } from "react-icons/fa";
import { AiFillContainer } from "react-icons/ai";
import { FaUserGraduate } from "react-icons/fa";

const JsonMenu = [
  { url: "/admin/home", icon: <MdHome />, label: "Home" },
  {
    url: "/admin/transactions",
    icon: <IoIosSend />,
    label: "Pedidos de Resgate",
  },
  /* {
    url: "/admin/transactions-done",
    icon: <AiFillMoneyCollect />,
    label: "Tranferências Realizadas",
  }, */
  {
    url: "/admin/financial-account",
    icon: <MdAccountBalanceWallet />,
    label: "Contas Financeiras",
  },
  { url: "/admin/campaign", icon: <MdEventNote />, label: "Campanhas" },
  {
    url: "/admin/requests",
    icon: <IoIosCreate />,
    label: "Moderar Alterações",
  },
  {
    url: "/admin/donations",
    icon: <FaDonate />,
    label: "Todas doações",
  },
  {
    url: "/admin/allotment",
    icon: <AiFillContainer />,
    label: "Lotes",
  },
  { url: "/admin/settings", icon: <MdSettings />, label: "Configurações" },
  { url: "/admin/students", icon: <FaUserGraduate />, label: "Alunos" },
  { url: "/admin/usermanagement", icon: <FaUserPlus />, label: "Usuários" }
];

export default JsonMenu;
