import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Wrapper } from "./styles";
//import { ListGroup } from "react-bootstrap";

//import { MdEventNote, MdSettings } from "react-icons/md";

import JsonMenu from "~/pages/__layouts/admin/Components/menu";

export default function Sidebar() {
  let location = useLocation();

  return (
    <Wrapper>
      <div className="sidebar-sticky">
        <ul className="nav flex-column">
          {JsonMenu.map((x, i) => (
            <li key={i} className="nav-item">
              <Link
                to={x.url}
                className={`nav-link ${
                  location.pathname.toLowerCase().includes(x.url.toLowerCase())
                    ? "active"
                    : ""
                }`}
              >
                {x.icon} {x.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Wrapper>
  );
}
