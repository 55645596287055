import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { signOut } from "~/store/modules/auth/actions";
//import { updateModalProfile } from "~/store/modules/header/actions";
import {
  updateProfileSuccess,
  updateEmailConfirmation,
} from "~/store/modules/user/actions";
import {
  setItemMenuProfile,
  changeEmail,
} from "~/store/modules/header/actions";

import { FaUserEdit } from "react-icons/fa";
import { RiLockPasswordLine } from "react-icons/ri";
import { FiLogOut } from "react-icons/fi";
import { MdEmail } from "react-icons/md";
import { IoMdMenu } from "react-icons/io";

import { Wrapper } from "./styles";

import logo from "~/assets/LogoP.png";

import imagePreLoading from "~/assets/imagePreLoading.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import JsonMenu from "~/components/Header/menu";

import validadePass from "~/util/validatePass";

import {
  Navbar,
  Nav,
  Container,
  Dropdown,
  Modal,
  Row,
  Col,
  Button,
  Card,
} from "react-bootstrap";

import api from "~/services/api";
import { toast } from "react-toastify";
import { Form, Input } from "@rocketseat/unform";
import CropImage from "~/components/CropImage";

export default function Header() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const template = useSelector((state) => state.user.template);

  const itemsMenu = useSelector((state) => state.header.itemsMenu) || ["all"];
  const modalEmail = useSelector((state) => state.header.modalEmail) || false;

  const [loaded, setloaded] = useState(true);

  const [modalLoading, setModalLoading] = useState(false);
  const [modalUser, setModalUser] = useState(false);
  const [modalUserPWD, setModalUserPWD] = useState(false);

  useEffect(() => {
    if (!!template) {
      dispatch(setItemMenuProfile(template));
    }
    if (modalEmail == true) {
      sendNewEmail(profile.email, profile.email);
    }
  }, []);

  const renderMenu = () => {
    switch (template) {
      case "donor":
        return (
          <>
            <Dropdown.Item onClick={() => setModalUser(true)}>
              <FaUserEdit /> Atualizar dados de cadastro
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setModalUserPWD(true)}>
              <RiLockPasswordLine /> Alterar minha senha
            </Dropdown.Item>
            <Dropdown.Item onClick={() => dispatch(signOut())}>
              <FiLogOut /> Logout
            </Dropdown.Item>
          </>
        );
        break;
      default:
        return (
          <>
            <Dropdown.Item onClick={() => dispatch(changeEmail())}>
              <MdEmail /> Alterar meu e-mail
            </Dropdown.Item>
            <Dropdown.Item onClick={() => dispatch(signOut())}>
              <FiLogOut /> Logout
            </Dropdown.Item>
          </>
        );
    }
  };

  const handleUpdate = async (data) => {
    setModalLoading(true);
    const { name } = data;
    try {
      const response = await api.put(`/giverarea/updategivername/${name}`);
      const res = response.data;

      const { message, entity, status } = res;
      if (response.status === 200 && status) {
        const { giverId, name, imageId } = entity;
        const user = { ...profile, giverId, name, imageId };

        dispatch(updateProfileSuccess(user));
        toast.success(`Perfil atualizado`);
        setModalUser(false);
        //dispatch(updateModalProfile());
      } else {
        toast.error(`Erro na alteração. ${message}`);
      }
    } catch (error) {
      toast.error(`Erro na comunicação com o servidor.`);
    } finally {
      setModalLoading(false);
    }
  };

  const sendNewEmail = async ( NewEmail, ConfirmEmail ) => {
    const Email = NewEmail.trim();
    const ConfirmarEmail = ConfirmEmail.trim();

    if (Email === ConfirmarEmail) {
      const response = await api.post(`/authentication/requestemailupdate`, {
        NewEmail: Email,
        ConfirmEmail: ConfirmarEmail,
      });
      const { message, status, entity } = response.data;
      if (status) {
        dispatch(updateEmailConfirmation(entity));
        //setModalNewEmail(false);
        dispatch(changeEmail());
        toast.success("Enviado. Entre em seu e-mail e confirme");
      } else {
        toast.error(message);
      }
    } else {
      toast.warn("Os e-mails tem que ser iguais");
    }
  };

  const handleNewPassword = async ({ currentPassword, newPassword, confirmNewPassword }) => {
    if (newPassword === confirmNewPassword) {    
      if (validadePass(newPassword)) {
        try {
          const passwordChangeForm = {
            oldPassword: currentPassword,
            newPassword: newPassword,
            confirmPassword: confirmNewPassword,
          }
  
          const response = await api.post(`/Authentication/ChangeUserPassword`, passwordChangeForm);
          const { status, message } = response.data;
  
          if (status) {
            toast.success(`Senha atualizada com sucesso`);
            setModalUserPWD(false);
          }
          else {
            toast.error(`Ocorreu um erro. ${message}`);
          } 
        } catch (error) {
          if (!!error.response && error.response.status !== 401) {
            if (error.response.status == 400) {
              const { errors } = error.response.data;
              const messageError = Object.entries(errors).map(e => e[1]).join(', ');
              toast.error(`Erro de validação: ${messageError}`);
            } else {
              toast.error("Erro na requisição.. tente novamente");
            }
          }
        }
      }
      else{
        toast.warn("A nova senha deve atender os seguintes requisitos: Conter no mínimo 6 "+
        "caracteres, letra maiúscula, letra minúscula e número");
      }
    }
    else {
      toast.warn("As senhas digitadas são divergentes!");
    }
  };

  return (
    <Wrapper>
      <Navbar bg="light" id="menuTop" variant="light" className="py-3">
        <Container>
          
          <Dropdown className="d-block d-sm-none">

            <Dropdown.Toggle
              variant="success"
              drop={`left`}
              id={`dropdown-button-icon`}
            >
                <IoMdMenu color="#000" size={22} />
            </Dropdown.Toggle>

            <Dropdown.Menu id="listItems">
              {JsonMenu.filter((y) => itemsMenu.includes(y.person)).map(
                (x, i) => (
                  <Dropdown.Item key={i} href={x.url}>
                    {x.label}
                  </Dropdown.Item>
                )
              )}
            </Dropdown.Menu>

          </Dropdown>

          <Link to="/">
            <img src={logo} className="logo" alt="Logo" />
          </Link>
          <Nav className="mr-auto d-none d-sm-block">
            {JsonMenu.filter((y) => itemsMenu.includes(y.person)).map(
              (x, i) => (
                <Link key={i} to={x.url} id="headerLinks" >
                  {x.label}
                </Link>
              )
            )}
          </Nav>
          <Nav className="">
            {profile ? (
              <>
                <span className="mr-2 align-self-center">
                  {/* Olá, {profile.name.split(" ")[0]} */}
                  Olá, {profile.name.split(" ")[0]}
                </span>
                <Link
                  to={template === "admin" ? "/admin/home" : "/dashboard"}
                  className="mr-0"
                >
                  <div
                    id="preloadlogo"
                    className="imgProfile"
                    style={{
                      display: !!loaded ? "block" : "none",
                    }}
                  />
                  <LazyLoadImage
                    effect="blur"
                    src={profile.avatar.url}
                    visibleByDefault={
                      profile.avatar.url.src === imagePreLoading
                    }
                    afterLoad={() => setloaded(false)}
                    className="imgProfile rounded-circle imagePeople text-center"
                  />
                </Link>
                <Dropdown id="dropDownMenu">
                  <Dropdown.Toggle
                    variant="light"
                    id="dropdown-basic"
                  ></Dropdown.Toggle>
                  <Dropdown.Menu alignRight>{renderMenu()}</Dropdown.Menu>
                </Dropdown>
              </>
            ) : (
                <>
                  <div className="align-self-center">
                    <Link to="/login" id="headerLinks" >Logar</Link>{" "}
                  </div>
                </>
              )}
          </Nav>
        </Container>
      </Navbar>

      {/* ModalChangeEMail */}
      {/* <Modal
        size="md"
        show={modalEmail}
        onHide={() => dispatch(changeEmail())}
        className="my-5"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body>
          <Card bg="light" text="dark">
            <Card.Header>
              <h4>Confirme seus dados</h4>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={sendNewEmail}>
                <Row>
                  <Col xs={12}>
                    <label>E-mail</label>
                    <Input
                      name="NewEmail"
                      type="email"
                      required
                      className="form-control mb-4"
                    />
                  </Col>
                  <Col xs={12}>
                    <label>Confirme E-mail</label>
                    <Input
                      name="ConfirmEmail"
                      type="email"
                      required
                      className="form-control mb-4"
                    />
                  </Col>
                  <Col xs={12}>
                    <Button variant="success" type="Submit" block>
                      Enviar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal> */}

      {/* Modal Atualizar Nome */}
      {profile !== null && template === "donor" && (
        <Modal
          className="mt-5"
          size="md"
          show={modalUser}
          onHide={() => setModalUser(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Atualizar
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center">
              <Col xs={10}>
                <Form initialData={profile} onSubmit={handleUpdate}>
                  <CropImage
                    id="cropComp"
                    data={profile.avatar}
                    route="/giverarea/uploadimage"
                  />
                  <Input
                    name="name"
                    className="form-control"
                    placeholder="Nome Completo"
                  />
                  <Button
                    type="submit"
                    className="my-4"
                    disabled={modalLoading}
                    block
                  >
                    {!modalLoading ? "Atualizar" : "Atualizando..."}
                  </Button>
                </Form>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}

      {/* Modal Atualizar Senha */}
      {template === "donor" && (
        <Modal
          className="mt-5"
          size="md"
          show={modalUserPWD}
          onHide={() => setModalUserPWD(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Atualizar sua senha
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center">
              <Col xs={10}>
                <Form onSubmit={handleNewPassword}>
                  <Input
                    type="password"
                    required
                    name="currentPassword"
                    className="form-control mb-2"
                    placeholder="Senha atual"
                  />
                  <Input
                    required
                    name="newPassword"
                    type="password"
                    className="form-control mb-2"
                    placeholder="Nova senha"
                  />
                  <Input
                    required
                    name="confirmNewPassword"
                    type="password"
                    className="form-control mb-2"
                    placeholder="Confirme sua nova senha"
                  />
                  <Button
                    type="submit"
                    className="my-4"
                    disabled={modalLoading}
                    block
                  >
                    {!modalLoading ? "Atualizar" : "Atualizando..."}
                  </Button>
                </Form>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </Wrapper>
  );
}
