import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { Doughnut, Bar } from "react-chartjs-2"; //http://jerairrest.github.io/react-chartjs-2/

import { Table, Col, Row, Card } from "react-bootstrap";
// import { Container } from './styles';

export default function Home() {
  const tenantProfile = useSelector((state) => state.header.tenantProfile);
  useEffect(() => {}, [tenantProfile]);

  const dataFinancial = {
    labels: ["Pendente", "Processando", "Resgatado"],
    datasets: [
      {
        data: [180, 240, 320],
        backgroundColor: ["#FFCE56", "#36A2EB", "#4eaf63"],
        hoverBackgroundColor: ["#FFCE56", "#36A2EB", "#4eaf63"],
      },
    ],
  };

  const dataTransfer = {
    labels: ["Pendente", "Concluído", "Negado"],
    datasets: [
      {
        data: [80, 240, 20],
        backgroundColor: ["#FFCE56", "#4eaf63", "#d83535"],
        hoverBackgroundColor: ["#FFCE56", "#4eaf63", "#d83535"],
      },
    ],
  };

  const dataBar = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Total de doações por mês",
        backgroundColor: "rgba(255,99,132,0.2)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: [65, 59, 80, 81, 56, 14, 0],
      },
    ],
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Home</h1>
      </div>

      <Row>
        <Col sm={12} md={6} className="mt-3">
          <Card>
            <Card.Header>Financeiro</Card.Header>
            <Card.Body>
              <Doughnut data={dataFinancial} />
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={6} className="mt-3">
          <Card>
            <Card.Header>Doações por período</Card.Header>
            <Card.Body>
              <Bar data={dataBar} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6} className="mt-3">
          <Card>
            <Card.Header>Pedidos de Resgate</Card.Header>
            <Card.Body>
              <Doughnut data={dataTransfer} />
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={6}></Col>
      </Row>
    </>
  );
}
